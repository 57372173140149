import { AxiosResponse } from "axios";
import { HTMLAttributeAnchorTarget } from "react";
import { combineReducers } from "redux";
import { replace } from "redux-first-history";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { ActionType, createAction, createAsyncAction, createReducer } from "typesafe-actions";
import t from "../../app/i18n";
import { RootState } from "../../common/types";
import messageUtils from "../../common/utils/messageUtils";
import { generateRandomToken, openUrl } from "../../common/utils/utils";
import { changeRunningRequestKeyAction } from "../ducks";
import api from "./api";
import { PartnerSsoType } from "./enums";
import { PARTNER_ROUTE_PATHS } from "./paths";
import {
  LogoutFromPartnerSso,
  MonlySsoProperties,
  PartnerConfigsAndSsoProperties,
  PartnerReducerState,
  PartnerSso,
  PartnerSsoTempData,
  ReplacePartnerConfigs,
  RequestPartnerSso,
  RequestPartnerSsoAuthRedirectUrl
} from "./types";

/**
 * ACTIONS - PARTNER CONFIG
 */
export const getPartnerConfigsAndSsoPropsActions = createAsyncAction(
  "partner-config/GET_REQUEST",
  "partner-config/GET_SUCCESS",
  "partner-config/GET_FAILURE"
)<void, PartnerConfigsAndSsoProperties, void>();

export const replacePartnerConfigsActions = createAsyncAction(
  "partner-config/REPLACE_REQUEST",
  "partner-config/REPLACE_SUCCESS",
  "partner-config/REPLACE_FAILURE"
)<ReplacePartnerConfigs, void, void>();

export const deleteStatePartnerConfigsAndSsoPropertiesAction = createAction("partner-config/DELETE_STATE_LIST")<void>();

/**
 * ACTIONS - PARTNER SSO
 */
export const getPartnerSsoActions = createAsyncAction(
  "partner-sso/GET_REQUEST",
  "partner-sso/GET_SUCCESS",
  "partner-sso/GET_FAILURE"
)<RequestPartnerSso, PartnerSso, void>();

export const logoutFromPartnerSsoActions = createAsyncAction(
  "partner-sso/LOGOUT_REQUEST",
  "partner-sso/LOGOUT_SUCCESS",
  "partner-sso/LOGOUT_FAILURE"
)<LogoutFromPartnerSso, void, void>();

export const monlyAuthorizationCodeGrantActions = createAsyncAction(
  "partner-sso/MONLY_AUTH_CODE_REQUEST",
  "partner-sso/MONLY_AUTH_CODE_SUCCESS",
  "partner-sso/MONLY_AUTH_CODE_FAILURE"
)<string, PartnerSso, void>();

export const deleteStatePartnerSsoAction = createAction("partner-sso/DELETE_STATE")<void>();

export const setUsernameRequiredForPartnerSsoTypeAction = createAction("partner-sso/SET_USERNAME_REQUIRED")<
  PartnerSsoType | undefined
>();

export const setStatePartnerSsoTempDataAction = createAction("partner-sso-temp-data/SET_STATE")<PartnerSsoTempData>();

export const deleteStatePartnerSsoTempDataAction = createAction("partner-sso-temp-data/DELETE_STATE")<void>();

/**
 * ACTIONS - PARTNER SSO OAUTH
 */
export const getPartnerSsoAuthRedirectUrlActions = createAsyncAction(
  "partner-sso-oauth/GET_AUTH_URL_REQUEST",
  "partner-sso-oauth/GET_AUTH_URL_SUCCESS",
  "partner-sso-oauth/GET_AUTH_URL_FAILURE"
)<RequestPartnerSsoAuthRedirectUrl, string, void>();

const actions = {
  getPartnerConfigsAndSsoPropsActions,
  replacePartnerConfigsActions,
  deleteStatePartnerConfigsAndSsoPropertiesAction,
  getPartnerSsoActions,
  logoutFromPartnerSsoActions,
  monlyAuthorizationCodeGrantActions,
  deleteStatePartnerSsoAction,
  setUsernameRequiredForPartnerSsoTypeAction,
  setStatePartnerSsoTempDataAction,
  deleteStatePartnerSsoTempDataAction,
  getPartnerSsoAuthRedirectUrlActions
};

export type PartnerConfigAction = ActionType<typeof actions>;

/**
 * REDUCERS
 */
const initialState: PartnerReducerState = {
  configsAndSsoProps: null,
  sso: null,
  usernameRequiredForSsoType: null
};

const configsAndSsoPropsReducer = createReducer(initialState.configsAndSsoProps)
  .handleAction(getPartnerConfigsAndSsoPropsActions.success, (_, { payload }) => payload)
  .handleAction(
    [getPartnerConfigsAndSsoPropsActions.failure, deleteStatePartnerConfigsAndSsoPropertiesAction],
    () => initialState.configsAndSsoProps
  );

const ssoReducer = createReducer(initialState.sso)
  .handleAction([getPartnerSsoActions.success, monlyAuthorizationCodeGrantActions.success], (_, { payload }) => payload)
  .handleAction(
    [getPartnerSsoActions.failure, monlyAuthorizationCodeGrantActions.failure, deleteStatePartnerSsoAction],
    () => initialState.sso
  );

const usernameRequiredForSsoTypeReducer = createReducer(initialState.usernameRequiredForSsoType).handleAction(
  setUsernameRequiredForPartnerSsoTypeAction,
  (_, { payload }) => payload ?? null
);

export const partnerReducer = combineReducers<PartnerReducerState>({
  configsAndSsoProps: configsAndSsoPropsReducer,
  sso: ssoReducer,
  usernameRequiredForSsoType: usernameRequiredForSsoTypeReducer
});

/**
 * SELECTORS
 */
const selectPartner = (state: RootState): PartnerReducerState => state.partner;

export const selectPartnerConfigsAndSsoProps = (state: RootState): PartnerConfigsAndSsoProperties | undefined =>
  selectPartner(state).configsAndSsoProps ?? undefined;
export const selectPartnerSso = (state: RootState): PartnerSso | undefined => selectPartner(state).sso ?? undefined;
export const selectUsernameRequiredForSsoType = (state: RootState): PartnerSsoType | undefined =>
  selectPartner(state).usernameRequiredForSsoType ?? undefined;

/**
 * SAGAS - PARTNER CONFIG
 */
function* getPartnerConfigsAndSsoProps() {
  try {
    const response: AxiosResponse<PartnerConfigsAndSsoProperties> = yield call(api.getPartnerConfigsAndSsoProps);
    yield put(getPartnerConfigsAndSsoPropsActions.success(response.data));
  } catch (error) {
    yield put(getPartnerConfigsAndSsoPropsActions.failure());
  }
}

function* replacePartnerConfigs({ payload }: ReturnType<typeof replacePartnerConfigsActions.request>) {
  try {
    const toCreate = payload.newConfigs?.filter(
      newConf => !payload.prevConfigs?.find(prevConf => newConf.id === prevConf.id)
    );
    if (toCreate?.length) {
      yield call(api.createPartnerConfigs, { configs: toCreate });
    }

    const toUpdate = payload.newConfigs?.filter(
      newConf => !!payload.prevConfigs?.find(prevConf => newConf.id === prevConf.id)
    );
    if (toUpdate?.length) {
      yield call(api.updatePartnerConfigs, { configs: toUpdate });
    }

    const toDelete = payload.prevConfigs?.filter(
      prevConf => !payload.newConfigs?.find(newConf => prevConf.id === newConf.id)
    );
    if (toDelete?.length) {
      yield call(
        api.deletePartnerConfigs,
        toDelete.map(conf => conf.id)
      );
    }

    yield put(replacePartnerConfigsActions.success());
    yield put(changeRunningRequestKeyAction());

    if (toCreate?.length && !toUpdate?.length && !toDelete?.length) {
      messageUtils.itemCreatedNotification();
    } else if (!toCreate?.length && !toUpdate?.length && toDelete?.length) {
      messageUtils.itemDeletedNotification();
    } else {
      messageUtils.itemUpdatedNotification();
    }

    yield put(getPartnerConfigsAndSsoPropsActions.request());
  } catch (error) {
    yield put(replacePartnerConfigsActions.failure());
  }
}

/**
 * SAGAS - PARTNER SSO
 */
function* getPartnerSso({ payload }: ReturnType<typeof getPartnerSsoActions.request>) {
  try {
    const response: AxiosResponse<PartnerSso> = yield call(api.getPartnerSso, payload);
    yield put(getPartnerSsoActions.success(response.data));

    const configsAndSsoProps: PartnerConfigsAndSsoProperties = yield select(selectPartnerConfigsAndSsoProps);
    switch (payload.ssoType) {
      case PartnerSsoType.MONLY:
        if (response.data) {
          redirectToMonlyAccessTokenLogin(configsAndSsoProps.monlySsoProperties, response.data.accessToken, "_blank");
        } else {
          const token = "MONLY_" + generateRandomToken(20);
          yield put(setStatePartnerSsoTempDataAction({ token }));
          redirectToMonlyAuthorizationCodeLogin(configsAndSsoProps.monlySsoProperties, token);
        }
        break;
      case PartnerSsoType.METLIFE:
        if (response.data?.redirectUrl) {
          openUrl(response.data.redirectUrl, "_blank");
        } else {
          yield put(setUsernameRequiredForPartnerSsoTypeAction(PartnerSsoType.METLIFE));
        }
        break;
    }
  } catch (error) {
    yield put(getPartnerSsoActions.failure());
  }
}

function* logoutFromPartnerSso({ payload }: ReturnType<typeof logoutFromPartnerSsoActions.request>) {
  try {
    yield call(api.logoutFromPartnerSso, payload);
    yield put(logoutFromPartnerSsoActions.success());
    messageUtils.successNotification({
      message: t("common.operationSuccess"),
      description: t("partnerSso.helpers.logoutSuccessful", {
        ssoTypes: payload.ssoTypes.map(ssoType => t("partnerSso.enums.ssoType." + ssoType)).join(", ")
      })
    });
  } catch (error) {
    yield put(logoutFromPartnerSsoActions.failure());
  }
}

function* monlyAuthorizationCodeGrant({ payload }: ReturnType<typeof monlyAuthorizationCodeGrantActions.request>) {
  try {
    const response: AxiosResponse<PartnerSso> = yield call(api.monlyAuthorizationCodeGrant, payload);
    yield put(monlyAuthorizationCodeGrantActions.success(response.data));

    const configsAndSsoPropertiesResponse: AxiosResponse<PartnerConfigsAndSsoProperties> = yield call(
      api.getPartnerConfigsAndSsoProps
    );
    redirectToMonlyAccessTokenLogin(
      configsAndSsoPropertiesResponse.data.monlySsoProperties,
      response.data.accessToken,
      "_self"
    );
  } catch (error) {
    yield put(monlyAuthorizationCodeGrantActions.failure());
  }
}

const redirectToMonlyAuthorizationCodeLogin = (monlySsoProps: MonlySsoProperties, token: string): void => {
  openUrl(
    `${monlySsoProps.baseUrl}/fe/authexternal/userlogin` +
      "?response_type=code" +
      `&redirect_uri=${window.location.origin}${PARTNER_ROUTE_PATHS.ssoOAuthClient.to}` +
      `&client_id=${monlySsoProps.clientId}` +
      `&state=${token}`,
    "_blank"
  );
};

const redirectToMonlyAccessTokenLogin = (
  monlySsoProps: MonlySsoProperties,
  accessToken: string,
  target: HTMLAttributeAnchorTarget
): void => {
  openUrl(`${monlySsoProps.baseUrl}/fe/authexternal/accesstokenlogin?access_token=${accessToken}`, target);
};

/**
 * SAGAS - PARTNER SSO OAUTH
 */
function* getPartnerSsoAuthRedirectUrl({ payload }: ReturnType<typeof getPartnerSsoAuthRedirectUrlActions.request>) {
  try {
    const response: AxiosResponse<string> = yield call(api.getAuthRedirectUrl, payload);
    yield put(getPartnerSsoAuthRedirectUrlActions.success(response.data));
    openUrl(response.data);
  } catch (error) {
    yield put(replace(PARTNER_ROUTE_PATHS.configs.to));
    yield put(getPartnerSsoAuthRedirectUrlActions.failure());
  }
}

export function* partnerSaga() {
  yield takeLatest(getPartnerConfigsAndSsoPropsActions.request, getPartnerConfigsAndSsoProps);
  yield takeLatest(replacePartnerConfigsActions.request, replacePartnerConfigs);
  yield takeLatest(getPartnerSsoActions.request, getPartnerSso);
  yield takeLatest(logoutFromPartnerSsoActions.request, logoutFromPartnerSso);
  yield takeLatest(monlyAuthorizationCodeGrantActions.request, monlyAuthorizationCodeGrant);
  yield takeLatest(getPartnerSsoAuthRedirectUrlActions.request, getPartnerSsoAuthRedirectUrl);
}
