import { AxiosResponse } from "axios";
import { Pathname } from "history";
import { generatePath } from "react-router-dom";
import { combineReducers } from "redux";
import { replace } from "redux-first-history";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { ActionType, createAction, createAsyncAction, createReducer } from "typesafe-actions";
import t from "../../app/i18n";
import {
  EntityIdObject,
  EntityObject,
  PageRequest,
  PageResult,
  RootState,
  TwoLevelEntityIdObject
} from "../../common/types";
import { initPageResult, initSearchPageResult } from "../../common/utils/apiUtils";
import messageUtils from "../../common/utils/messageUtils";
import { openBlobFile, openUrl, removeFromArray } from "../../common/utils/utils";
import { changeRunningRequestKeyAction, selectRouterLocationPathname } from "../ducks";
import api from "./api";
import { CONTRACT_ROUTE_PATHS } from "./paths";
import {
  ChangeContractVerificationStatus,
  Contract,
  ContractAttachment,
  ContractCommission,
  ContractExternalView,
  ContractFilterExportRequest,
  ContractFilterPageRequest,
  ContractFilterPageResult,
  ContractList,
  ContractReducerState,
  ContractVehiclesExportRequest,
  CreateUpdateContract,
  RecalculatePredictedCommissionsRequest
} from "./types";

/**
 * ACTIONS
 */
export const filterContractsActions = createAsyncAction(
  "contract/FILTER_REQUEST",
  "contract/FILTER_SUCCESS",
  "contract/FILTER_FAILURE"
)<ContractFilterPageRequest, ContractFilterPageResult, void>();

export const importContractsActions = createAsyncAction(
  "contract/IMPORT_REQUEST",
  "contract/IMPORT_SUCCESS",
  "contract/IMPORT_FAILURE"
)<FormData, void, void>();

export const downloadContractsImportResultActions = createAsyncAction(
  "contract/IMPORT_RESULT_REQUEST",
  "contract/IMPORT_RESULT_SUCCESS",
  "contract/IMPORT_RESULT_FAILURE"
)<EntityIdObject, void, void>();

export const downloadContractsExportActions = createAsyncAction(
  "contract/EXPORT_REQUEST",
  "contract/EXPORT_SUCCESS",
  "contract/EXPORT_FAILURE"
)<ContractFilterExportRequest, void, void>();

export const downloadContractVehiclesExportActions = createAsyncAction(
  "contract/EXPORT_VEHICLE_REQUEST",
  "contract/EXPORT_VEHICLE_SUCCESS",
  "contract/EXPORT_VEHICLE_FAILURE"
)<EntityObject<ContractVehiclesExportRequest>, void, void>();

export const recalculatePredictedCommissionsActions = createAsyncAction(
  "contract/RECALCULATE_PREDICTED_COMMISSIONS_REQUEST",
  "contract/RECALCULATE_PREDICTED_COMMISSIONS_SUCCESS",
  "contract/RECALCULATE_PREDICTED_COMMISSIONS_FAILURE"
)<RecalculatePredictedCommissionsRequest, void, void>();

export const getContractActions = createAsyncAction(
  "contract/GET_REQUEST",
  "contract/GET_SUCCESS",
  "contract/GET_FAILURE"
)<EntityIdObject, Contract, void>();

export const getContractExternalViewActions = createAsyncAction(
  "contract/GET_EXTERNAL_VIEW_REQUEST",
  "contract/GET_EXTERNAL_VIEW_SUCCESS",
  "contract/GET_EXTERNAL_VIEW_FAILURE"
)<EntityIdObject, ContractExternalView, void>();

export const filterContractCommissionsActions = createAsyncAction(
  "contract/FILTER_COMMISSIONS_REQUEST",
  "contract/FILTER_COMMISSIONS_SUCCESS",
  "contract/FILTER_COMMISSIONS_FAILURE"
)<EntityObject<PageRequest>, PageResult<ContractCommission>, void>();

export const createContractActions = createAsyncAction(
  "contract/CREATE_REQUEST",
  "contract/CREATE_SUCCESS",
  "contract/CREATE_FAILURE"
)<CreateUpdateContract, Contract, void>();

export const changeContractVerificationStatusActions = createAsyncAction(
  "contract/CHANGE_VERIFICATION_STATUS_REQUEST",
  "contract/CHANGE_VERIFICATION_STATUS_SUCCESS",
  "contract/CHANGE_VERIFICATION_STATUS_FAILURE"
)<EntityObject<ChangeContractVerificationStatus>, Contract, void>();

export const updateContractActions = createAsyncAction(
  "contract/UPDATE_REQUEST",
  "contract/UPDATE_SUCCESS",
  "contract/UPDATE_FAILURE"
)<EntityObject<CreateUpdateContract>, Contract, void>();

export const deleteContractActions = createAsyncAction(
  "contract/DELETE_REQUEST",
  "contract/DELETE_SUCCESS",
  "contract/DELETE_FAILURE"
)<EntityIdObject, void, void>();

export const uploadContractAttachmentsActions = createAsyncAction(
  "contract-attachment/UPLOAD_REQUEST",
  "contract-attachment/UPLOAD_SUCCESS",
  "contract-attachment/UPLOAD_FAILURE"
)<EntityObject<FormData>, Contract, void>();

export const downloadContractAttachmentActions = createAsyncAction(
  "contract-attachment/DOWNLOAD_REQUEST",
  "contract-attachment/DOWNLOAD_SUCCESS",
  "contract-attachment/DOWNLOAD_FAILURE"
)<TwoLevelEntityIdObject, void, void>();

export const downloadContractAttachmentsAsZipActions = createAsyncAction(
  "contract-attachment/DOWNLOAD_ATTACHMENTS_AS_ZIP_REQUEST",
  "contract-attachment/DOWNLOAD_ATTACHMENTS_AS_ZIP_SUCCESS",
  "contract-attachment/DOWNLOAD_ATTACHMENTS_AS_ZIP_FAILURE"
)<EntityIdObject, void, void>();

export const deleteContractAttachmentActions = createAsyncAction(
  "contract-attachment/DELETE_REQUEST",
  "contract-attachment/DELETE_SUCCESS",
  "contract-attachment/DELETE_FAILURE"
)<TwoLevelEntityIdObject, Contract, void>();

export const deleteStateContractPageAction = createAction("contract/DELETE_STATE_LIST")<void>();
export const deleteStateContractDetailAction = createAction("contract/DELETE_STATE_DETAIL")<void>();

const actions = {
  filterContractsActions,
  importContractsActions,
  downloadContractsImportResultActions,
  downloadContractsExportActions,
  downloadContractVehiclesExportActions,
  recalculatePredictedCommissionsActions,
  getContractActions,
  getContractExternalViewActions,
  filterContractCommissionsActions,
  createContractActions,
  changeContractVerificationStatusActions,
  updateContractActions,
  deleteContractActions,
  uploadContractAttachmentsActions,
  downloadContractAttachmentActions,
  downloadContractAttachmentsAsZipActions,
  deleteContractAttachmentActions,
  deleteStateContractPageAction,
  deleteStateContractDetailAction
};

export type ContractAction = ActionType<typeof actions>;

/**
 * REDUCERS
 */
const initialState: ContractReducerState = {
  currentPage: {
    ...initSearchPageResult<ContractList>(),
    report: undefined,
    orderBy: [],
    orderDirections: [],
    institutionIds: [],
    productIds: [],
    signerIds: [],
    managerIds: [],
    gainerIds: [],
    statuses: [],
    verificationStatuses: []
  },
  contractDetail: null,
  contractCommissionsPage: initPageResult<ContractCommission>()
};

const currentPageReducer = createReducer(initialState.currentPage)
  .handleAction(filterContractsActions.success, (_, { payload }) => payload)
  .handleAction(
    [filterContractsActions.failure, deleteContractActions.success, deleteStateContractPageAction],
    () => initialState.currentPage
  );

const contractDetailReducer = createReducer(initialState.contractDetail)
  .handleAction(
    [
      getContractActions.success,
      createContractActions.success,
      changeContractVerificationStatusActions.success,
      updateContractActions.success,
      uploadContractAttachmentsActions.success,
      deleteContractAttachmentActions.success
    ],
    (_, { payload }) => payload
  )
  .handleAction(
    [getContractActions.failure, deleteContractActions.success, deleteStateContractDetailAction],
    () => initialState.contractDetail
  );

const contractCommissionsPageReducer = createReducer(initialState.contractCommissionsPage)
  .handleAction(filterContractCommissionsActions.success, (_, { payload }) => payload)
  .handleAction(
    [filterContractCommissionsActions.failure, deleteStateContractDetailAction],
    () => initialState.contractCommissionsPage
  );

export const contractReducer = combineReducers<ContractReducerState>({
  currentPage: currentPageReducer,
  contractDetail: contractDetailReducer,
  contractCommissionsPage: contractCommissionsPageReducer
});

/**
 * SELECTORS
 */
const selectContract = (state: RootState): ContractReducerState => state.contract;

export const selectCurrentContractsPage = (state: RootState): ContractFilterPageResult =>
  selectContract(state).currentPage;
export const selectContractDetail = (state: RootState): Contract | undefined =>
  selectContract(state).contractDetail ?? undefined;
export const selectContractCommissionsPage = (state: RootState): PageResult<ContractCommission> =>
  selectContract(state).contractCommissionsPage;

/**
 * SAGAS
 */
function* filterContracts({ payload }: ReturnType<typeof filterContractsActions.request>) {
  try {
    const response: AxiosResponse<ContractFilterPageResult> = yield call(api.filterContracts, payload);
    yield put(filterContractsActions.success(response.data));
  } catch (error) {
    yield put(filterContractsActions.failure());
  }
}

function* importContracts({ payload }: ReturnType<typeof importContractsActions.request>) {
  try {
    const response: AxiosResponse<number> = yield call(api.importContracts, payload);
    yield put(importContractsActions.success());
    yield put(changeRunningRequestKeyAction());
    messageUtils.successNotification({
      message: t("common.operationSuccess"),
      description: t("contract.helpers.import.processStarted", { count: response.data })
    });
  } catch (error) {
    yield put(importContractsActions.failure());
  }
}

function* downloadContractsImportResult({ payload }: ReturnType<typeof downloadContractsImportResultActions.request>) {
  try {
    const response: AxiosResponse<Blob> = yield call(api.downloadContractsImportResult, payload);
    openBlobFile(response, true);
    yield put(downloadContractsImportResultActions.success());
  } catch (error) {
    yield put(downloadContractsImportResultActions.failure());
  }
}

function* downloadContractsExport({ payload }: ReturnType<typeof downloadContractsExportActions.request>) {
  try {
    const response: AxiosResponse<Blob> = yield call(api.downloadContractsExport, payload);
    openBlobFile(response, true);
    yield put(downloadContractsExportActions.success());
  } catch (error) {
    yield put(downloadContractsExportActions.failure());
  }
}

function* downloadContractVehiclesExport({
  payload
}: ReturnType<typeof downloadContractVehiclesExportActions.request>) {
  try {
    const response: AxiosResponse<Blob> = yield call(api.downloadContractVehiclesExport, payload);
    openBlobFile(response, true);
    yield put(downloadContractVehiclesExportActions.success());
  } catch (error) {
    yield put(downloadContractVehiclesExportActions.failure());
  }
}

function* recalculatePredictedCommissions({
  payload
}: ReturnType<typeof recalculatePredictedCommissionsActions.request>) {
  try {
    const response: AxiosResponse<number> = yield call(api.recalculatePredictedCommissions, payload);
    yield put(recalculatePredictedCommissionsActions.success());
    yield put(changeRunningRequestKeyAction());
    messageUtils.successNotification({
      message: t("common.operationSuccess"),
      description: t("contract.helpers.successfulPredictedCommissionsRecalculation", { count: response.data })
    });
  } catch (error) {
    yield put(recalculatePredictedCommissionsActions.failure());
  }
}

function* getContractDetail({ payload }: ReturnType<typeof getContractActions.request>) {
  try {
    const response: AxiosResponse<Contract> = yield call(api.getContract, payload);
    yield put(getContractActions.success(response.data));
  } catch (error) {
    yield put(getContractActions.failure());
  }
}

function* getContractExternalView({ payload }: ReturnType<typeof getContractExternalViewActions.request>) {
  try {
    const response: AxiosResponse<ContractExternalView> = yield call(api.getContractExternalView, payload);
    yield put(getContractExternalViewActions.success(response.data));
    openUrl(response.data.redirectUrl, "_blank");
  } catch (error) {
    yield put(getContractExternalViewActions.failure());
  }
}

function* filterContractCommissions({ payload }: ReturnType<typeof filterContractCommissionsActions.request>) {
  try {
    const response: AxiosResponse<PageResult<ContractCommission>> = yield call(api.filterContractCommissions, payload);
    yield put(filterContractCommissionsActions.success(response.data));
  } catch (error) {
    yield put(filterContractCommissionsActions.failure());
  }
}

function* createContract({ payload }: ReturnType<typeof createContractActions.request>) {
  try {
    const response: AxiosResponse<Contract> = yield call(api.createContract, payload);
    yield put(createContractActions.success(response.data));
    yield put(replace(generatePath(CONTRACT_ROUTE_PATHS.detail.to, { id: response.data.id })));
    messageUtils.itemCreatedNotification();
  } catch (error) {
    yield put(createContractActions.failure());
  }
}

function* changeContractVerificationStatus({
  payload
}: ReturnType<typeof changeContractVerificationStatusActions.request>) {
  try {
    const response: AxiosResponse<Contract> = yield call(api.changeContractVerificationStatus, payload);
    yield put(changeContractVerificationStatusActions.success(response.data));
    yield put(changeRunningRequestKeyAction());
    messageUtils.itemUpdatedNotification();

    const location: Pathname = yield select(selectRouterLocationPathname);
    if (
      generatePath(CONTRACT_ROUTE_PATHS.list.to) === location ||
      generatePath(CONTRACT_ROUTE_PATHS.unpaid.to) === location
    ) {
      const page: ContractFilterPageResult = yield select(selectCurrentContractsPage);
      yield put(
        filterContractsActions.request({
          ...page,
          pageData: undefined,
          totalElementsCount: undefined,
          pageElementsCount: undefined,
          pagesCount: undefined,
          isFirst: undefined,
          isLast: undefined
        } as ContractFilterPageResult)
      );
    }
  } catch (error) {
    yield put(changeContractVerificationStatusActions.failure());
  }
}

function* updateContract({ payload }: ReturnType<typeof updateContractActions.request>) {
  try {
    const response: AxiosResponse<Contract> = yield call(api.updateContract, payload);
    yield put(updateContractActions.success(response.data));
    yield put(changeRunningRequestKeyAction());
    messageUtils.itemUpdatedNotification();
  } catch (error) {
    yield put(updateContractActions.failure());
  }
}

function* deleteContract({ payload }: ReturnType<typeof deleteContractActions.request>) {
  try {
    yield call(api.deleteContract, payload);
    yield put(deleteContractActions.success());
    yield put(replace(CONTRACT_ROUTE_PATHS.list.to));
  } catch (error) {
    yield put(deleteContractActions.failure());
  }
}

function* uploadContractAttachments({ payload }: ReturnType<typeof uploadContractAttachmentsActions.request>) {
  try {
    const response: AxiosResponse<ContractAttachment[]> = yield call(api.uploadContractAttachments, payload);
    const contract: Contract = yield select(selectContractDetail);
    yield put(uploadContractAttachmentsActions.success({ ...contract, attachments: response.data }));
  } catch (error) {
    yield put(uploadContractAttachmentsActions.failure());
  }
}

function* downloadContractAttachment({ payload }: ReturnType<typeof downloadContractAttachmentActions.request>) {
  try {
    const response: AxiosResponse<Blob> = yield call(api.downloadContractAttachment, payload);
    openBlobFile(response);
    yield put(downloadContractAttachmentActions.success());
  } catch (error) {
    yield put(downloadContractAttachmentActions.failure());
  }
}

function* downloadContractAttachmentsAsZip({
  payload
}: ReturnType<typeof downloadContractAttachmentsAsZipActions.request>) {
  try {
    const response: AxiosResponse<Blob> = yield call(api.downloadContractAttachmentsAsZip, payload);
    openBlobFile(response, true);
    yield put(downloadContractAttachmentsAsZipActions.success());
  } catch (error) {
    yield put(downloadContractAttachmentsAsZipActions.failure());
  }
}

function* deleteContractAttachment({ payload }: ReturnType<typeof deleteContractAttachmentActions.request>) {
  try {
    yield call(api.deleteContractAttachment, payload);
    const contract: Contract = yield select(selectContractDetail);
    yield put(
      deleteContractAttachmentActions.success({
        ...contract,
        attachments: removeFromArray(contract.attachments, item => item.id === payload.id2)
      })
    );
  } catch (error) {
    yield put(deleteContractAttachmentActions.failure());
  }
}

export function* contractSaga() {
  yield takeLatest(filterContractsActions.request, filterContracts);
  yield takeLatest(importContractsActions.request, importContracts);
  yield takeLatest(downloadContractsImportResultActions.request, downloadContractsImportResult);
  yield takeLatest(downloadContractsExportActions.request, downloadContractsExport);
  yield takeLatest(downloadContractVehiclesExportActions.request, downloadContractVehiclesExport);
  yield takeLatest(recalculatePredictedCommissionsActions.request, recalculatePredictedCommissions);
  yield takeLatest(getContractActions.request, getContractDetail);
  yield takeLatest(getContractExternalViewActions.request, getContractExternalView);
  yield takeLatest(filterContractCommissionsActions.request, filterContractCommissions);
  yield takeLatest(createContractActions.request, createContract);
  yield takeLatest(changeContractVerificationStatusActions.request, changeContractVerificationStatus);
  yield takeLatest(updateContractActions.request, updateContract);
  yield takeLatest(deleteContractActions.request, deleteContract);

  yield takeLatest(uploadContractAttachmentsActions.request, uploadContractAttachments);
  yield takeLatest(downloadContractAttachmentActions.request, downloadContractAttachment);
  yield takeLatest(downloadContractAttachmentsAsZipActions.request, downloadContractAttachmentsAsZip);
  yield takeLatest(deleteContractAttachmentActions.request, deleteContractAttachment);
}
