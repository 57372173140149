import { green, red } from "@ant-design/colors";
import { Col, Divider, Row, Statistic } from "antd";
import t from "../../../../../app/i18n";
import AntIcon from "../../../../../common/components/icons/AntIcon";
import {
  formatIntegerLocaleCurrency,
  formatLocaleCurrency,
  formatLocaleDate,
  formatPhoneNumber,
  formatPlaceOfInsurance
} from "../../../../../common/utils/formatUtils";
import { tBoolean, tInterval } from "../../../../../common/utils/translationUtils";
import { ClientsListDrawerView, createClientsData } from "../../../../client/components/drawers/ClientsListDrawerView";
import { ClientFormType, ClientType } from "../../../../client/enums";
import { NaturalClient, SelfEmployedClient } from "../../../../client/types";
import { InstitutionEnum } from "../../../../institution/enums";
import { RealtyCalc, RealtyCalcResultData, RealtyFormClients, RealtyGenForm } from "../../realty/types";
import { CalcResult } from "../../types";

export type RealtyCalcSummaryViewData = {
  calcData: RealtyCalc;
  clients: RealtyFormClients;
  formData: RealtyGenForm;
  selectedInsurance: CalcResult<RealtyCalcResultData>;
};

type Props = {
  data: RealtyCalcSummaryViewData;
};

const SPAN_LABEL = 5;
const SPAN_VALUE = 19;

const RealtyCalcSummaryView = ({ data: { selectedInsurance, formData, calcData, clients } }: Props) => {
  const insuredClient = formData.clientsData.policyHolderIsAlsoInsured ? clients.policyHolder : clients.insured;

  const policyHolderIdentityCard =
    clients.policyHolder?.type === ClientType.NATURAL || clients.policyHolder?.type === ClientType.SELF_EMPLOYED
      ? formData.clientsData.policyHolderIdentityCardNumber
      : undefined;

  const policyHolderClient = policyHolderIdentityCard
    ? { ...(clients.policyHolder as NaturalClient | SelfEmployedClient), identityCardNumber: policyHolderIdentityCard }
    : clients.policyHolder;

  return (
    <div>
      <Row>
        <Col flex={1}>
          <Statistic
            className="statistic-small"
            title={t("common.insuranceInstitution")}
            value={selectedInsurance.insuranceInstitution.name}
          />
        </Col>

        {selectedInsurance.coverage ? (
          <Col flex={1}>
            <Statistic
              className="statistic-small"
              title={<span style={{ textTransform: "capitalize" }}>{t("calc.realty.sections.coverage")}</span>}
              value={selectedInsurance.coverage}
            />
          </Col>
        ) : undefined}

        <Col flex={5}>
          <Statistic
            className="statistic-small"
            title={t("calc.realty.enums.buildingType._label")}
            value={t("calc.realty.enums.buildingType." + calcData.generalBuildingData.type)}
          />
        </Col>
      </Row>

      <Row className="margin-top-small">
        <Col flex={1}>
          <Divider orientation="left">{t("calc.realty.attrs.clientsData._label")}</Divider>
          <ClientsListDrawerView
            clientsData={createClientsData([
              {
                client: policyHolderClient,
                type: ClientFormType.POLICY_HOLDER
              },
              {
                client: insuredClient,
                type: ClientFormType.INSURED
              },
              {
                client: clients.vinculation,
                type: ClientFormType.VINCULATION
              }
            ])}
          />
        </Col>
      </Row>
      <Row>
        <Col flex={1}>
          <Row>
            <Col span={SPAN_LABEL}>{t("calc.realty.attrs.clientsData.policyHolderPhone")}</Col>
            <Col span={SPAN_VALUE}>{formatPhoneNumber(formData.clientsData.policyHolderPhone)}</Col>
          </Row>
          <Row>
            <Col span={SPAN_LABEL}>{t("calc.realty.attrs.clientsData.policyHolderEmail")}</Col>
            <Col span={SPAN_VALUE}>{formData.clientsData.policyHolderEmail}</Col>
          </Row>
        </Col>
      </Row>

      <Row className="margin-top-small">
        <Col flex={1}>
          <Divider orientation="left">{t("calc.realty.sections.realtyInsurance")}</Divider>
          <Row>
            <Col span={SPAN_LABEL}>{t("calc.realty.attrs.generalBuildingData.placeOfInsurance._label")}</Col>
            <Col span={SPAN_VALUE}> {formatPlaceOfInsurance(calcData.generalBuildingData.placeOfInsurance)}</Col>
          </Row>
          <Row>
            <Col span={SPAN_LABEL}>{t("calc.realty.enums.buildingState._label")}</Col>
            <Col span={SPAN_VALUE}>{t("calc.realty.enums.buildingState." + calcData.generalBuildingData.state)}</Col>
          </Row>
          <Row>
            <Col span={SPAN_LABEL}>{t("calc.realty.attrs.generalBuildingData.constructionYear")}</Col>
            <Col span={SPAN_VALUE}> {calcData.generalBuildingData.constructionYear}</Col>
          </Row>
          <Row>
            <Col span={SPAN_LABEL}>{t("calc.realty.attrs.generalBuildingData.inCity")}</Col>
            <Col span={SPAN_VALUE}> {tBoolean(calcData.generalBuildingData.inCity)}</Col>
          </Row>
          <Row>
            <Col span={SPAN_LABEL}>{t("calc.realty.attrs.generalBuildingData.permanentlyOccupied")}</Col>
            <Col span={SPAN_VALUE}> {tBoolean(calcData.generalBuildingData.permanentlyOccupied)}</Col>
          </Row>
          {calcData.houseOrRecreationalBuildingData ? (
            <>
              {calcData.houseOrRecreationalBuildingData.constructionMaterial ? (
                <Row>
                  <Col span={SPAN_LABEL}> {t("calc.realty.enums.constructionMaterial._label")}</Col>
                  <Col span={SPAN_VALUE}>
                    {t(
                      "calc.realty.enums.constructionMaterial." +
                        calcData.houseOrRecreationalBuildingData.constructionMaterial
                    )}
                  </Col>
                </Row>
              ) : undefined}
              <Row>
                <Col span={SPAN_LABEL}>{t("calc.realty.attrs.houseOrRecreationalBuildingData.buildArea")}</Col>
                <Col span={SPAN_VALUE}>
                  {calcData.houseOrRecreationalBuildingData.buildArea} m<sup>2</sup>
                </Col>
              </Row>
              <Row>
                <Col span={SPAN_LABEL}>{t("calc.realty.enums.roofType._label")}</Col>
                <Col span={SPAN_VALUE}>
                  <Row>
                    <Col span={SPAN_VALUE}>
                      {t("calc.realty.enums.roofType." + calcData.houseOrRecreationalBuildingData.roofType)}
                      {`, `}
                      {t("calc.realty.attrs.houseOrRecreationalBuildingData.atticArea")}:{" "}
                      {calcData.houseOrRecreationalBuildingData.atticArea} m<sup>2</sup>
                      {`, `}
                      {calcData.houseOrRecreationalBuildingData.residentialAtticArea ? (
                        <>
                          {t("calc.realty.attrs.houseOrRecreationalBuildingData.residentialAtticArea")}:{" "}
                          {calcData.houseOrRecreationalBuildingData.residentialAtticArea} m<sup>2</sup>
                        </>
                      ) : undefined}
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col span={SPAN_LABEL}>{t("calc.realty.sections.overgroundFloorAreas")}</Col>
                <Col span={SPAN_VALUE}>
                  {calcData.houseOrRecreationalBuildingData.overgroundFloorAreas.map((value, index) => (
                    <span key={index}>
                      {index > 0 ? `, ` : undefined}
                      {tInterval(
                        "calc.realty.attrs.houseOrRecreationalBuildingData.overgroundFloorAreas_interval",
                        index + 1
                      )}
                      : {value} m<sup>2</sup>
                    </span>
                  ))}
                </Col>
              </Row>
              <Row>
                <Col span={SPAN_LABEL}>{t("calc.realty.sections.areas")}</Col>
                <Col span={SPAN_VALUE}>
                  <span>
                    {t("calc.realty.attrs.houseOrRecreationalBuildingData.cellarArea")}:{" "}
                    {calcData.houseOrRecreationalBuildingData.cellarArea ?? 0} m<sup>2</sup>
                  </span>
                  {`, `}
                  <span>
                    {t("calc.realty.attrs.houseOrRecreationalBuildingData.residentialCellarArea")}:{" "}
                    {calcData.houseOrRecreationalBuildingData.residentialCellarArea ?? 0} m<sup>2</sup>
                  </span>
                  {`, `}
                  <span>
                    {t("calc.realty.attrs.houseOrRecreationalBuildingData.garageArea")}:{" "}
                    {calcData.houseOrRecreationalBuildingData.garageArea ?? 0} m<sup>2</sup>
                  </span>
                </Col>
              </Row>
            </>
          ) : undefined}
          {calcData.apartmentData ? (
            <>
              {calcData.apartmentData.constructionMaterial ? (
                <Row>
                  <Col span={SPAN_LABEL}>{t("calc.realty.enums.constructionMaterial._label")}</Col>
                  <Col span={SPAN_VALUE}>
                    {t("calc.realty.enums.constructionMaterial." + calcData.apartmentData.constructionMaterial)}
                  </Col>
                </Row>
              ) : undefined}
              <Row>
                <Col span={SPAN_LABEL}>{t("calc.realty.attrs.apartmentData.floor")}</Col>
                <Col span={SPAN_VALUE}>{calcData.apartmentData.floor}</Col>
              </Row>
              <Row>
                <Col span={SPAN_LABEL}>{t("calc.realty.attrs.apartmentData.apartmentNumber")}</Col>
                <Col span={SPAN_VALUE}>{calcData.apartmentData.apartmentNumber}</Col>
              </Row>
              <Row>
                <Col span={SPAN_LABEL}>{t("calc.realty.sections.areas")}</Col>
                <Col span={SPAN_VALUE}>
                  <span>
                    {t("calc.realty.attrs.apartmentData.floorArea")}: {calcData.apartmentData.floorArea} m<sup>2</sup>
                  </span>
                  {`,  `}
                  <span>
                    {t("calc.realty.sections.balconyFloorArea")}: {calcData.apartmentData.balconyArea ?? 0} m
                    <sup>2</sup>
                  </span>
                  {`,  `}
                  <span>
                    {t("calc.realty.attrs.apartmentData.terraceArea")}: {calcData.apartmentData.terraceArea ?? 0} m
                    <sup>2</sup>
                  </span>
                  {`,  `}
                  <span>
                    {t("calc.realty.attrs.apartmentData.cellarArea")}: {calcData.apartmentData.cellarArea ?? 0} m
                    <sup>2</sup>
                  </span>
                  {`,  `}
                  <span>
                    {t("calc.realty.attrs.apartmentData.garageArea")}: {calcData.apartmentData.garageArea ?? 0} m
                    <sup>2</sup>
                  </span>
                </Col>
              </Row>
            </>
          ) : undefined}
        </Col>
      </Row>

      <Row className="margin-top-small">
        <Col flex={1}>
          <Divider orientation="left">{t("calc.helpers.summaryData.insuranceAndCoverages")}</Divider>
          <Row>
            <Col span={6}>
              <Row>
                <Col span={20}>{t("calc.realty.attrs.realtyInsuranceData._label")}</Col>
                <Col span={4}>
                  {calcData.realtyInsuranceData ? (
                    <AntIcon type="check-circle-two-tone" twoToneColor={green[5]} />
                  ) : (
                    <AntIcon type="close-circle-two-tone" twoToneColor={red[5]} />
                  )}
                </Col>
              </Row>
            </Col>
            <Col span={18}>
              {calcData.realtyInsuranceData ? (
                <>
                  {t("calc.realty.attrs.realtyInsuranceData.insuranceAmount")}:{" "}
                  {formatIntegerLocaleCurrency(selectedInsurance.data.realtyInsuranceAmount)}
                </>
              ) : undefined}
            </Col>
          </Row>

          <Row>
            <Col span={6}>
              <Row>
                <Col span={20}>{t("calc.realty.attrs.householdInsuranceData._label")}</Col>
                <Col span={4}>
                  {calcData.householdInsuranceData ? (
                    <AntIcon type="check-circle-two-tone" twoToneColor={green[5]} />
                  ) : (
                    <AntIcon type="close-circle-two-tone" twoToneColor={red[5]} />
                  )}
                </Col>
              </Row>
            </Col>
            <Col span={18}>
              {calcData.householdInsuranceData ? (
                <>
                  {t("calc.realty.attrs.householdInsuranceData.insuranceAmount")}:{" "}
                  {formatIntegerLocaleCurrency(selectedInsurance.data.householdInsuranceAmount)}
                </>
              ) : undefined}
            </Col>
          </Row>

          <Row>
            <Col span={6}>
              <Row>
                <Col span={20}>{t("calc.realty.attrs.liabilityInsuranceData._label")}</Col>
                <Col span={4}>
                  {selectedInsurance.data.liabilityInsuranceAmount ? (
                    <AntIcon type="check-circle-two-tone" twoToneColor={green[5]} />
                  ) : (
                    <AntIcon type="close-circle-two-tone" twoToneColor={red[5]} />
                  )}
                </Col>
              </Row>
            </Col>
            <Col span={18}>
              {selectedInsurance.data.liabilityInsuranceAmount ? (
                <>
                  {t("contract.attrs.insurances.insuranceData.insuranceAmount")}:{" "}
                  {formatIntegerLocaleCurrency(selectedInsurance.data.liabilityInsuranceAmount)}
                </>
              ) : undefined}
            </Col>
          </Row>

          <Row>
            <Col span={6}>
              <Row>
                <Col span={20}>
                  {t(
                    selectedInsurance.insuranceInstitution.institutionEnum === InstitutionEnum.PREMIUM
                      ? "calc.realty.results.complicityAltName"
                      : "calc.realty.results.complicity"
                  )}
                </Col>
                <Col span={4}>
                  {selectedInsurance.data.complicity > 0 ? (
                    <AntIcon type="check-circle-two-tone" twoToneColor={green[5]} />
                  ) : (
                    <AntIcon type="close-circle-two-tone" twoToneColor={red[5]} />
                  )}
                </Col>
              </Row>
            </Col>
            <Col span={18}>
              {selectedInsurance.data.complicity > 0 ? (
                <>{formatIntegerLocaleCurrency(selectedInsurance.data.complicity)}</>
              ) : undefined}
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className="margin-top-small">
        <Col flex={1}>
          <Divider orientation="left">{t("calc.vehicle.results.additionalInfo")}</Divider>
          <Row>
            <Col span={SPAN_LABEL}>{t("calc.vehicle.attrs.generalData.effectiveBeginningDate")}</Col>
            <Col span={SPAN_VALUE}>{formatLocaleDate(calcData.generalInsuranceData.effectiveBeginningDate)}</Col>
          </Row>
          <Row>
            <Col span={SPAN_LABEL}> {t("contract.attrs.annualPremium")}</Col>
            <Col span={SPAN_VALUE}> {formatLocaleCurrency(selectedInsurance.data.annualPremium)}</Col>
          </Row>
          <Row>
            <Col span={SPAN_LABEL}>{t("contract.enums.paymentFrequency._label")}</Col>
            <Col span={SPAN_VALUE}>
              {t("contract.enums.paymentFrequency." + calcData.generalInsuranceData.paymentFrequency)}
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default RealtyCalcSummaryView;
