import { Button, Dropdown, Modal, Space } from "antd";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import { MenuInfo } from "rc-menu/lib/interface";
import { useSelector } from "react-redux";
import { generatePath, Link } from "react-router-dom";
import t from "../../../../../app/i18n";
import ActionTextIcon from "../../../../../common/components/icons/ActionTextIcon";
import AntIcon from "../../../../../common/components/icons/AntIcon";
import ComponentWithPermission from "../../../../../common/security/authorization/ComponentWithPermission";
import { Permission } from "../../../../../common/security/authorization/enums";
import { RootState } from "../../../../../common/types";
import { serializeParams } from "../../../../../common/utils/apiUtils";
import { QueryKeys, URLQuery } from "../../../../../common/utils/queryUtils";
import { hasPermission } from "../../../../../common/utils/utils";
import { selectPermissions } from "../../../../auth/ducks";
import { CALC_ROUTE_PATHS } from "../../../../calculator/paths";
import { InstitutionEnum } from "../../../../institution/enums";
import { CONTRACT_NUMBER_QUERY_PARAM, TAB } from "../../../containers/ContractFormsContainer";
import {
  changeContractVerificationStatusActions,
  deleteContractActions,
  getContractExternalViewActions
} from "../../../ducks";
import { ContractType, InsuranceType } from "../../../enums";
import { CONTRACT_ROUTE_PATHS } from "../../../paths";
import {
  Contract,
  GenericInsurance,
  InsuranceContract,
  LifeInsurance,
  RealtyInsurance,
  TravelInsurance,
  VehicleInsurance
} from "../../../types";
import { isTravelOrRealtyOrVehicleInsurance } from "../../../utils";
import ContractVerificationStatusButtonView from "./ContractVerificationStatusButtonView";

const TERMINATION_ACTION_KEY = "contract-dropdown-termination";
const DELETE_ACTION_KEY = "contract-dropdown-delete";
const LOAD_TO_CALCULATOR_ACTION_KEY = "contract-dropdown-load-calculator";

const getDropdownItems = (contract: Contract, permissions: Permission[], deletePermission: Permission) => {
  const hasDeletePermission = hasPermission(permissions, deletePermission);
  const dropdownItems: ItemType[] = [];

  if (contract.type === ContractType.INSURANCE_CONTRACT) {
    const insuranceContract = { ...contract } as InsuranceContract;

    dropdownItems.push({
      key: TERMINATION_ACTION_KEY,
      label: (
        <Link
          to={
            generatePath(CONTRACT_ROUTE_PATHS.forms.to) +
            "?" +
            serializeParams({ tab: TAB.TERMINATION, [CONTRACT_NUMBER_QUERY_PARAM]: insuranceContract.identifier })
          }
          target="_blank"
        >
          <ActionTextIcon text={t("contract.actions.terminate")} color="blue" icon="stop" />
        </Link>
      )
    });

    const insurance = insuranceContract.insurances[0] as
      | TravelInsurance
      | RealtyInsurance
      | VehicleInsurance
      | GenericInsurance
      | LifeInsurance;
    let hasLoadToCalculatorPermission = false;
    let calcPath = "";
    const queryParams = new URLSearchParams({ [QueryKeys.CONTRACT_ID]: contract.id });

    switch (insurance.type) {
      case InsuranceType.MTPL:
        calcPath = CALC_ROUTE_PATHS.vehicle.to;
        hasLoadToCalculatorPermission = hasPermission(permissions, Permission.MTPL_CALCULATE);
        break;
      case InsuranceType.CRASH:
      case InsuranceType.GAP:
      case InsuranceType.PAS:
        calcPath = CALC_ROUTE_PATHS.vehicle.to;
        hasLoadToCalculatorPermission = hasPermission(permissions, Permission.CRASH_CALCULATE);
        break;
      case InsuranceType.REALTY:
        calcPath = CALC_ROUTE_PATHS.realty.to;
        hasLoadToCalculatorPermission = hasPermission(permissions, Permission.REALTY_CALCULATE);
        break;
      case InsuranceType.TRAVEL:
        calcPath = CALC_ROUTE_PATHS.travel.to;
        hasLoadToCalculatorPermission = hasPermission(permissions, Permission.TRAVEL_CALCULATE);
        break;
    }

    if (hasLoadToCalculatorPermission && isTravelOrRealtyOrVehicleInsurance(insurance) && insurance.calcData) {
      dropdownItems.push({
        key: LOAD_TO_CALCULATOR_ACTION_KEY,
        label: (
          <ActionTextIcon
            path={`${calcPath}${URLQuery.toString(queryParams)}`}
            target="_blank"
            icon="select"
            color="green"
            text={t("contract.actions.loadToCalculator")}
          />
        )
      });
    }
  }

  if (hasDeletePermission) {
    dropdownItems.push({
      key: DELETE_ACTION_KEY,
      label: <ActionTextIcon text={t("contract.actions.deleteContract")} color="red" icon="delete" />
    });
  }

  return dropdownItems;
};

interface Props {
  contract: Contract;
  updatePermission: Permission;
  deletePermission: Permission;
  hasVerificationStatusChangePermission: boolean;
  onVerificationStatusChange: typeof changeContractVerificationStatusActions.request;
  onExternalViewGet: typeof getContractExternalViewActions.request;
  onDelete: typeof deleteContractActions.request;
  onUpdateClick: () => void;
}

const ContractHeaderActionsView = ({
  contract,
  updatePermission,
  deletePermission,
  hasVerificationStatusChangePermission,
  onVerificationStatusChange,
  onExternalViewGet,
  onDelete,
  onUpdateClick
}: Props) => {
  const permissions = useSelector<RootState, Permission[]>(selectPermissions);
  const dropdownItems = getDropdownItems(contract, permissions, deletePermission);

  const handleMenuDropdownClick = (clickInfo: MenuInfo): void => {
    switch (clickInfo.key) {
      case DELETE_ACTION_KEY:
        Modal.confirm({
          title: t("contract.helpers.deleteContractConfirm"),
          okText: t("common.yes"),
          cancelText: t("common.no"),
          onOk: () => {
            onDelete({ id: contract.id });
          }
        });
        break;
    }
  };

  return (
    <Space>
      <ComponentWithPermission permissions={[updatePermission]}>
        <Button type="primary" icon={<AntIcon type="edit" />} onClick={onUpdateClick}>
          {t("contract.actions.updateContract")}
        </Button>
      </ComponentWithPermission>

      <ContractVerificationStatusButtonView
        contract={contract}
        hasChangePermission={hasVerificationStatusChangePermission}
        onStatusChange={onVerificationStatusChange}
      />

      {(contract.institution.institutionEnum === InstitutionEnum.AXA_NON_LIFE ||
        contract.institution.institutionEnum === InstitutionEnum.AXA_LIFE ||
        contract.institution.institutionEnum === InstitutionEnum.UNIQA ||
        contract.institution.institutionEnum === InstitutionEnum.UNIQA_INVEST ||
        contract.institution.institutionEnum === InstitutionEnum.UNIQA_DSS ||
        contract.institution.institutionEnum === InstitutionEnum.UNIQA_DDS) && (
        <Button icon={<AntIcon type="delivered-procedure" />} onClick={() => onExternalViewGet({ id: contract.id })}>
          {t("contract.actions.showInExternalView")}
        </Button>
      )}

      {dropdownItems.length > 0 && (
        <Dropdown
          trigger={["click"]}
          placement="bottomRight"
          menu={{
            items: dropdownItems,
            onClick: handleMenuDropdownClick
          }}
        >
          <Button icon={<AntIcon type="more" />} />
        </Dropdown>
      )}
    </Space>
  );
};

export default ContractHeaderActionsView;
