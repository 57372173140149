import { Button, Card, Col, Form, Modal, Radio, Row } from "antd";
import isEqual from "lodash/isEqual";
import { FieldData } from "rc-field-form/lib/interface";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import t from "../../../../../../app/i18n";
import AntIcon, { type AntIconType } from "../../../../../../common/components/icons/AntIcon";
import { rowGutter } from "../../../../../../common/constants";
import type { FieldConstraintViolation } from "../../../../../../common/types";
import { resolveFormValidationError, setErrorsToForm } from "../../../../../../common/utils/formUtils";
import { useScrollToTopOnLoad } from "../../../../../../common/utils/hooksUtils";
import messageUtils from "../../../../../../common/utils/messageUtils";
import { isDefinedValue } from "../../../../../../common/utils/utils";
import { validations } from "../../../../../../common/utils/validationUtils";
import { CalcValidationGroup, ClientFormType, ClientType } from "../../../../../client/enums";
import {
  CreateUpdateContractClient,
  LegalClient,
  NaturalClient,
  SelfEmployedClient
} from "../../../../../client/types";
import {
  clientToCreateUpdateContractClient,
  createUpdateContractClientToClient,
  processClientsDataViolations,
  resolveClientIdentifier,
  useClientValidation
} from "../../../../../client/utils";
import { DashboardNoticePosition } from "../../../../../dashboard/enums";
import { CalcType } from "../../../../enums";
import { CALC_ROUTE_PATHS } from "../../../../paths";
import CalcNavigation from "../../../components/CalcNavigation";
import CalcNoticesIconView from "../../../components/CalcNoticesIconView";
import { CalcDataSource, CalcResult } from "../../../types";
import { deleteStateRealtyCalcResultsAction } from "../../ducks";
import { BuildingType, NearbyBuildingType } from "../../enums";
import { RealtyCalc, RealtyCalcResultData, RealtyFormClients, RealtyOfferType } from "../../types";
import RealtyCalcResults from "../result/RealtyCalcResults";
import RealtyCalcApartmentSection from "./sections/RealtyCalcApartmentSection";
import RealtyCalcGeneralSection from "./sections/RealtyCalcGeneralSection";
import RealtyCalcHouseholdRealtySection from "./sections/RealtyCalcHouseholdRealtySection";
import RealtyCalcHouseSection from "./sections/RealtyCalcHouseSection";
import RealtyCalcInsuranceSection from "./sections/RealtyCalcInsuranceSection";

interface Props {
  calcData?: RealtyCalc;
  calcDataSource: CalcDataSource;
  calcResults: CalcResult<RealtyCalcResultData>[][];
  policyHolder?: NaturalClient | SelfEmployedClient | LegalClient;
  onCalcResultsDelete: typeof deleteStateRealtyCalcResultsAction;
  onCalculationFormSubmit: (calcData: RealtyCalc) => void;
  onClientChange: (type: ClientFormType, client: NaturalClient | SelfEmployedClient | LegalClient) => RealtyFormClients;
  onGenerateContractClick: (calcData: RealtyCalc, selectedResult: CalcResult<RealtyCalcResultData>) => void;
  onGenerateOfferClick: (type: RealtyOfferType, calcData: RealtyCalc) => void;
  onSaveDraftClick: (calcData: RealtyCalc, overwriteExisting: boolean) => void;
  onResetCalculatorClick: () => void;
  onCalcDataSourceReset: () => void;
}

const POLICY_HOLDER_INDEX_MAP = new Map<ClientFormType, number>([[ClientFormType.POLICY_HOLDER, 0]]);

const RealtyCalcWrapper = (props: Props) => {
  useScrollToTopOnLoad();

  const [form] = Form.useForm<RealtyCalc>();
  const clientValidation = useClientValidation();

  const [resultsOpen, setResultsOpen] = useState<boolean>(false);
  const [clientsViolationErrors, setClientsViolationErrors] = useState<Map<ClientFormType, FieldConstraintViolation[]>>(
    new Map()
  );

  useEffect(() => {
    if (props.calcData) {
      const {
        generalBuildingData,
        apartmentData,
        realtyInsuranceData,
        householdInsuranceData,
        liabilityInsuranceData,
        generalInsuranceData,
        clientsData
      } = props.calcData;

      const policyHolder = clientsData.policyHolderIndex
        ? createUpdateContractClientToClient(clientsData.clients?.[0] as CreateUpdateContractClient)
        : undefined;

      form.setFieldsValue({
        ...props.calcData,
        realtyInsuranceEnabled: !!realtyInsuranceData,
        householdInsuranceEnabled: !!householdInsuranceData,
        generalBuildingData: {
          ...generalBuildingData,
          business: !!generalBuildingData.business,
          inCity: !!generalBuildingData.inCity,
          permanentlyOccupied: !!generalBuildingData.permanentlyOccupied,
          occupiedByDisabledPerson: !!generalBuildingData.occupiedByDisabledPerson,
          rented: !!generalBuildingData.rented
        },
        apartmentData: apartmentData
          ? {
              ...apartmentData,
              topFloor: !!apartmentData.topFloor
            }
          : undefined,
        realtyInsuranceData: realtyInsuranceData
          ? {
              ...realtyInsuranceData,
              expertTestimony: !!realtyInsuranceData.expertTestimony,
              nearbyBuildingReinsurances: (realtyInsuranceData.nearbyBuildingReinsurances || []).map(r => ({
                ...r,
                placeOfGarageSameAsBuilding:
                  r.type === NearbyBuildingType.DETACHED_GARAGE && !isDefinedValue(r.placeOfGarageSameAsBuilding)
                    ? true
                    : r.placeOfGarageSameAsBuilding
              }))
            }
          : undefined,
        liabilityInsuranceData: {
          ...liabilityInsuranceData,
          extendedLiability: !!liabilityInsuranceData.extendedLiability
        },
        generalInsuranceData: {
          ...generalInsuranceData,
          cyberneticReinsurance: !!generalInsuranceData.cyberneticReinsurance,
          warrantyReinsurance: !!generalInsuranceData.warrantyReinsurance,
          cyclingReinsurance: !!generalInsuranceData.cyclingReinsurance,
          loanReinsurance: !!generalInsuranceData.loanReinsurance,
          rentReinsurance: !!generalInsuranceData.rentReinsurance,
          complicity: !!generalInsuranceData.complicity,
          vinculation: !!generalInsuranceData.vinculation,
          crossSelling: generalInsuranceData.crossSelling
            ? {
                ...generalInsuranceData.crossSelling,
                unionHealthContract: !!generalInsuranceData.crossSelling.unionHealthContract
              }
            : undefined
        },
        clientsData: {
          ...props.calcData.clientsData,
          clients: undefined,
          policyHolderIndex: undefined,
          policyHolderIdentifier:
            props.calcData.clientsData.policyHolderIdentifier ||
            (policyHolder ? resolveClientIdentifier(policyHolder) : undefined)
        }
      });
      props.onCalcDataSourceReset();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (clientValidation.errorResponse?.violations?.length) {
      setClientsViolationErrors(
        processClientsDataViolations(
          POLICY_HOLDER_INDEX_MAP,
          "clientsData.clients",
          clientValidation.errorResponse?.violations
        )
      );
    }
  }, [clientValidation.errorResponse]);

  useEffect(() => {
    return () => {
      clientValidation.onErrorResponseDelete();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (props.calcDataSource === "init" && props.calcResults.length > 0) {
      setResultsOpen(true);
    }
  }, [props.calcResults]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleResultsSaveDraftClick = (overwriteExisting: boolean): void => {
    setResultsOpen(false);
    if (checkAreCalcFormDataUnchanged()) {
      props.onSaveDraftClick(processAndGetCalcFormData(), overwriteExisting);
    }
  };

  const handleResultsGenerateOfferClick = (type: RealtyOfferType): void => {
    setResultsOpen(false);
    if (checkAreCalcFormDataUnchanged()) {
      props.onGenerateOfferClick(type, processAndGetCalcFormData());
    }
  };

  const handleResultGenerateContractClick = (result: CalcResult<RealtyCalcResultData>): void => {
    setResultsOpen(false);
    if (checkAreCalcFormDataUnchanged()) {
      props.onGenerateContractClick(processAndGetCalcFormData(), result);
    }
  };

  const handleResultShowErrorsClick = (result: CalcResult<RealtyCalcResultData>): void => {
    if (result.error?.violations) {
      setErrorsToForm(form, "calc.realty.attrs", result.error.violations);
      setResultsOpen(false);
      setClientsViolationErrors(
        processClientsDataViolations(POLICY_HOLDER_INDEX_MAP, "clientsData.clients", result.error.violations)
      );
    }
  };

  const handleFormFieldsChange = (changedFields: FieldData[]): void => {
    if (
      props.calcResults.length > 0 &&
      changedFields?.length > 0 &&
      !(changedFields.length === 1 && changedFields[0]?.name?.toString()?.includes("orientationNumber"))
    ) {
      props.onCalcResultsDelete();
    }
  };

  const handleCalculationFormSubmit = (): void => {
    form
      .validateFields()
      .then(values => {
        if (!props.policyHolder || clientsViolationErrors.has(ClientFormType.POLICY_HOLDER)) {
          messageUtils.errorNotification({ message: t("common.error"), description: t("calc.validations.formError") });
        } else {
          if (props.calcDataSource !== "init") {
            props.onCalcDataSourceReset();
          }
          props.onCalculationFormSubmit(processAndGetCalcFormData(values));
        }
      })
      .catch(errorInfo => {
        messageUtils.errorNotification({ message: t("common.error"), description: t("calc.validations.formError") });
        resolveFormValidationError(errorInfo);
      });
  };

  const handlePolicyHolderChange = (client?: NaturalClient | SelfEmployedClient | LegalClient): void => {
    if (props.calcResults.length > 0 && !isEqual(props.policyHolder, client)) {
      props.onCalcResultsDelete();
    }

    if (client) {
      props.onClientChange(ClientFormType.POLICY_HOLDER, client);

      clientValidation.onValidate({
        prefix: `clientsData.clients[0]`,
        client: clientToCreateUpdateContractClient(client),
        validationGroup: CalcValidationGroup.CALCULATE
      });

      if (client.type !== ClientType.NATURAL) {
        form.setFieldsValue({
          householdInsuranceEnabled: false,
          generalInsuranceData: {
            crossSelling: {
              unionHealthContract: false
            }
          }
        });
      }
    }
  };

  const handlePolicyHolderViolationErrorsDelete = (): void => {
    setClientsViolationErrors(new Map<ClientFormType, FieldConstraintViolation[]>());
  };

  const checkAreCalcFormDataUnchanged = (): boolean => {
    const calcDataUnchanged = isEqual(props.calcData, processAndGetCalcFormData());

    if (!calcDataUnchanged) {
      let warningMessage;
      switch (props.calcDataSource) {
        case "calcDraft":
        case "genDraft":
          warningMessage = t("calc.validations.calcDataChangedDraft");
          break;
        case "calcData":
        case "genData":
          warningMessage = t("calc.validations.calcDataChangedInit");
          break;
        default:
          warningMessage = t("calc.validations.calcDataChanged");
          break;
      }

      Modal.warning({
        title: t("common.warning"),
        content: warningMessage,
        okText: t("calc.actions.recalculationSubmit"),
        maskClosable: true,
        onOk: handleCalculationFormSubmit,
        onCancel: handleCalculationFormSubmit
      });
    }

    if (props.calcDataSource !== "init") {
      props.onCalcDataSourceReset();
    }

    return calcDataUnchanged;
  };

  const processAndGetCalcFormData = (calcData?: RealtyCalc): RealtyCalc => {
    const processedValues = calcData ? { ...calcData } : (form.getFieldsValue() as RealtyCalc);
    processedValues.type = CalcType.REALTY;

    delete processedValues.realtyInsuranceEnabled;
    if (processedValues.realtyInsuranceData && !processedValues.realtyInsuranceData.nearbyBuildingReinsurances) {
      processedValues.realtyInsuranceData.nearbyBuildingReinsurances = [];
    }

    delete processedValues.householdInsuranceEnabled;
    if (processedValues.householdInsuranceData && !processedValues.householdInsuranceData.equipmentReinsurances) {
      processedValues.householdInsuranceData.equipmentReinsurances = [];
    }

    processedValues.clientsData = {
      ...processedValues.clientsData,
      clients: props.policyHolder ? [clientToCreateUpdateContractClient(props.policyHolder)] : [],
      policyHolderIndex: POLICY_HOLDER_INDEX_MAP.get(ClientFormType.POLICY_HOLDER) as number,
      policyHolderIdentifier: undefined
    };

    return processedValues;
  };

  const resolvePageTitle = (
    buildingType: BuildingType,
    realtyInsuranceEnabled: boolean,
    householdInsuranceEnabled: boolean
  ): string => {
    let pageTitle;
    switch (buildingType) {
      case BuildingType.HOUSE:
        pageTitle = t("calc.realty.titles.house");
        break;
      case BuildingType.APARTMENT:
        pageTitle = t("calc.realty.titles.apartment");
        break;
      case BuildingType.RECREATIONAL_BUILDING:
        pageTitle = t("calc.realty.titles.recreationalBuilding");
        break;
      default:
        return t("calc.realty.helpers.noBuildingType");
    }

    const insurancesTranslations = [];
    if (realtyInsuranceEnabled) {
      insurancesTranslations.push(t("calc.realty.sections.realtyInsurance"));
    }
    if (householdInsuranceEnabled) {
      insurancesTranslations.push(t("calc.realty.sections.householdInsurance"));
    }

    return insurancesTranslations.length === 0 ? pageTitle : `${pageTitle} (${insurancesTranslations.join(", ")})`;
  };

  const resolveBuildingTypeIcon = (buildingType: BuildingType): AntIconType => {
    switch (buildingType) {
      case BuildingType.HOUSE:
        return "home";
      case BuildingType.APARTMENT:
        return "hdd";
      case BuildingType.RECREATIONAL_BUILDING:
        return "shop";
    }
  };

  return (
    <>
      <Form form={form} layout="vertical" name="realtyCalcForm" onFieldsChange={handleFormFieldsChange}>
        <Form.Item
          noStyle
          shouldUpdate={(prev, next) =>
            prev.generalBuildingData.type !== next.generalBuildingData.type ||
            prev.realtyInsuranceEnabled !== next.realtyInsuranceEnabled ||
            prev.householdInsuranceEnabled !== next.householdInsuranceEnabled
          }
        >
          {({ getFieldValue }) => (
            <Card
              className="card-box card-box--inner-extra"
              title={
                <h2>
                  {resolvePageTitle(
                    getFieldValue(["generalBuildingData", "type"]),
                    getFieldValue(["realtyInsuranceEnabled"]),
                    getFieldValue(["householdInsuranceEnabled"])
                  )}
                </h2>
              }
              extra={
                <>
                  <CalcNoticesIconView position={DashboardNoticePosition.REALTY_CALC} />
                  <Link to={CALC_ROUTE_PATHS.realtyDraft.to}>
                    <Button icon={<AntIcon type="form" />} className="secondary-button">
                      {t("calc.actions.showDrafts")}
                    </Button>
                  </Link>
                </>
              }
            >
              <Row gutter={rowGutter} justify="center">
                <Col span={24} className="center-align">
                  <Form.Item
                    name={["generalBuildingData", "type"]}
                    rules={[validations.notNull]}
                    className="no-bottom-space"
                  >
                    <Radio.Group size="large" buttonStyle="solid" className="radio-group-icon">
                      {Object.values(BuildingType).map(type => (
                        <Radio.Button
                          value={type}
                          key={type}
                          style={{ width: "140px", paddingLeft: "10px", paddingRight: "10px" }}
                        >
                          <AntIcon type={resolveBuildingTypeIcon(type)} />
                          <span>{t("calc.realty.enums.buildingType." + type)}</span>
                        </Radio.Button>
                      ))}
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          )}
        </Form.Item>

        <RealtyCalcGeneralSection
          form={form}
          initialClientsData={props.calcData?.clientsData}
          calcDataSource={props.calcDataSource}
          policyHolder={props.policyHolder}
          clientsViolationErrors={clientsViolationErrors}
          onPolicyHolderChange={handlePolicyHolderChange}
          onPolicyHolderViolationErrorsDelete={handlePolicyHolderViolationErrorsDelete}
        />

        <Form.Item
          noStyle
          shouldUpdate={(prev, next) => prev.generalBuildingData.type !== next.generalBuildingData.type}
        >
          {({ getFieldValue }) => {
            switch (getFieldValue(["generalBuildingData", "type"]) as BuildingType) {
              case BuildingType.APARTMENT:
                return <RealtyCalcApartmentSection form={form} />;
              case BuildingType.HOUSE:
              case BuildingType.RECREATIONAL_BUILDING:
                return <RealtyCalcHouseSection />;
              default:
                return null;
            }
          }}
        </Form.Item>

        <RealtyCalcHouseholdRealtySection form={form} policyHolderType={props.policyHolder?.type} />

        <RealtyCalcInsuranceSection policyHolderType={props.policyHolder?.type} />
      </Form>

      <RealtyCalcResults
        open={resultsOpen}
        calcResults={props.calcResults}
        onClose={() => setResultsOpen(false)}
        onSaveDraftClick={handleResultsSaveDraftClick}
        onGenerateOfferClick={handleResultsGenerateOfferClick}
        onGenerateContractClick={handleResultGenerateContractClick}
        onShowErrorsClick={handleResultShowErrorsClick}
      />

      <CalcNavigation
        hasResults={props.calcResults.length > 0}
        onFormSubmit={handleCalculationFormSubmit}
        onShowResultsClick={() => setResultsOpen(true)}
        onResetCalculatorClick={props.onResetCalculatorClick}
      />
    </>
  );
};

export default RealtyCalcWrapper;
