import { AxiosResponse } from "axios";
import { combineReducers } from "redux";
import { replace } from "redux-first-history";
import { call, put, takeLatest } from "redux-saga/effects";
import { ActionType, createAction, createAsyncAction, createReducer } from "typesafe-actions";
import t from "../../../app/i18n";
import { EntityIdObject, RootState } from "../../../common/types";
import { initPageResult } from "../../../common/utils/apiUtils";
import messageUtils from "../../../common/utils/messageUtils";
import { openBlobFile } from "../../../common/utils/utils";
import contractApi from "../../contract/api";
import { Contract } from "../../contract/types";
import { isInsuranceContract } from "../../contract/utils";
import dashboardApi from "../../dashboard/api";
import {
  DashboardNotice,
  DashboardNoticeFilterPageRequest,
  DashboardNoticeFilterPageResult
} from "../../dashboard/types";
import { CalcType } from "../enums";
import api from "./api";
import { OfferType } from "./enums";
import {
  calculateRealtyActions,
  generateRealtyActions,
  generateRealtyOfferActions,
  RealtyCalcAction,
  realtyCalcReducer,
  setRealtyInitialGenDataAction
} from "./realty/ducks";
import { RealtyCalcResultData } from "./realty/types";
import {
  calculateTravelActions,
  generateTravelActions,
  generateTravelOfferActions,
  setTravelInitialGenDataAction,
  TravelCalcAction,
  travelCalcReducer
} from "./travel/ducks";
import { TravelCalcResultData } from "./travel/types";
import {
  CalcAttachment,
  CalcAttachmentInfo,
  CalcResponse,
  CalcResultData,
  CalcsReducerState,
  GenResponse
} from "./types";
import {
  isRealtyGenData,
  isTravelGenData,
  isVehicleGenData,
  prepareGenDataForCalculatorFromContract,
  sortAndGroupCalcResults
} from "./utils";
import {
  calculateVehicleActions,
  generateVehicleActions,
  generateVehicleOfferActions,
  setVehicleInitialGenDataAction,
  VehicleCalcAction,
  vehicleCalcReducer
} from "./vehicle/ducks";
import { VehicleCalcResultData } from "./vehicle/types";
import { sortAndGroupVehicleCalcResults } from "./vehicle/utils";

/**
 * ACTIONS
 */
export const downloadCardReaderActions = createAsyncAction(
  "calc/DOWNLOAD_CARD_READER_REQUEST",
  "calc/DOWNLOAD_CARD_READER_SUCCESS",
  "calc/DOWNLOAD_CARD_READER_FAILURE"
)<void, void, void>();

export const filterCalcNoticesActions = createAsyncAction(
  "calc/FILTER_NOTICES_REQUEST",
  "calc/FILTER_NOTICES_SUCCESS",
  "calc/FILTER_NOTICES_FAILURE"
)<DashboardNoticeFilterPageRequest, DashboardNoticeFilterPageResult, void>();

export const loadContractToCalculatorActions = createAsyncAction(
  "calc/LOAD_CONTRACT_TO_CALCULATOR_REQUEST",
  "calc/LOAD_CONTRACT_TO_CALCULATOR_SUCCESS",
  "calc/LOAD_CONTRACT_TO_CALCULATOR_FAILURE"
)<EntityIdObject, void, void>();

export const deleteStateCalcNoticesPageAction = createAction("calc/DELETE_STATE_NOTICES_LIST")<void>();

const actions = {
  downloadCardReaderActions,
  filterCalcNoticesActions,
  loadContractToCalculatorActions,
  deleteStateCalcNoticesPageAction
};

export type CalcAction = ActionType<typeof actions> | RealtyCalcAction | TravelCalcAction | VehicleCalcAction;

/**
 * REDUCERS
 */
const initialState: DashboardNoticeFilterPageResult = {
  ...initPageResult<DashboardNotice>(),
  position: undefined,
  onlyUnclosed: true
};

const noticesPageReducer = createReducer(initialState)
  .handleAction(filterCalcNoticesActions.success, (_, { payload }) => payload)
  .handleAction([filterCalcNoticesActions.failure, deleteStateCalcNoticesPageAction], () => initialState);

export const calcsReducer = combineReducers<CalcsReducerState>({
  vehicle: vehicleCalcReducer,
  realty: realtyCalcReducer,
  travel: travelCalcReducer,
  noticesPage: noticesPageReducer
});

/**
 * SELECTORS
 */
const selectCalcs = (state: RootState): CalcsReducerState => state.calculator.calcs;

export const selectCalcNoticesPage = (state: RootState): DashboardNoticeFilterPageResult =>
  selectCalcs(state).noticesPage;

/**
 * SAGAS
 */
function* calculate({
  payload
}:
  | ReturnType<typeof calculateVehicleActions.request>
  | ReturnType<typeof calculateTravelActions.request>
  | ReturnType<typeof calculateRealtyActions.request>) {
  try {
    const response: AxiosResponse<CalcResponse<CalcResultData>> = yield call(api.calculate, payload);
    switch (payload.type) {
      case CalcType.MTPL:
      case CalcType.CRASH:
      case CalcType.MTPL_CRASH:
      case CalcType.GAP:
      case CalcType.PAS:
        yield put(
          calculateVehicleActions.success(
            sortAndGroupVehicleCalcResults(response.data as CalcResponse<VehicleCalcResultData>)
          )
        );
        break;
      case CalcType.TRAVEL:
        yield put(
          calculateTravelActions.success(
            sortAndGroupCalcResults<TravelCalcResultData>(response.data as CalcResponse<TravelCalcResultData>)
          )
        );
        break;
      case CalcType.REALTY:
        yield put(
          calculateRealtyActions.success(
            sortAndGroupCalcResults<RealtyCalcResultData>(response.data as CalcResponse<RealtyCalcResultData>)
          )
        );
        break;
    }
  } catch (error) {
    switch (payload.type) {
      case CalcType.MTPL:
      case CalcType.CRASH:
      case CalcType.MTPL_CRASH:
      case CalcType.GAP:
      case CalcType.PAS:
        yield put(calculateVehicleActions.failure());
        break;
      case CalcType.TRAVEL:
        yield put(calculateTravelActions.failure());
        break;
      case CalcType.REALTY:
        yield put(calculateRealtyActions.failure());
        break;
    }
  }
}

function* generate({
  payload
}:
  | ReturnType<typeof generateVehicleActions.request>
  | ReturnType<typeof generateTravelActions.request>
  | ReturnType<typeof generateRealtyActions.request>) {
  try {
    let attachmentInfos: CalcAttachmentInfo[] | undefined = undefined;

    if (payload.attachmentsToUpload?.size) {
      const formData = new FormData();
      payload.attachmentsToUpload.forEach((file, type) => {
        formData.append("files", file);
        formData.append("types", type);
      });
      const uploadResponse: AxiosResponse<CalcAttachment[]> = yield call(api.uploadCalcAttachments, formData);
      attachmentInfos = uploadResponse.data.map<CalcAttachmentInfo>(a => ({ id: a.id, type: a.type }));
    }

    const response: AxiosResponse<GenResponse> = yield call(api.generate, {
      ...payload,
      attachments: attachmentInfos,
      attachmentsToUpload: undefined
    });

    switch (payload.type) {
      case CalcType.MTPL:
      case CalcType.CRASH:
      case CalcType.MTPL_CRASH:
      case CalcType.GAP:
      case CalcType.PAS:
        yield put(generateVehicleActions.success(response.data));
        break;
      case CalcType.TRAVEL:
        yield put(generateTravelActions.success(response.data));
        break;
      case CalcType.REALTY:
        yield put(generateRealtyActions.success(response.data));
        break;
    }
  } catch (error) {
    switch (payload.type) {
      case CalcType.MTPL:
      case CalcType.CRASH:
      case CalcType.MTPL_CRASH:
      case CalcType.GAP:
      case CalcType.PAS:
        yield put(generateVehicleActions.failure());
        break;
      case CalcType.TRAVEL:
        yield put(generateTravelActions.failure());
        break;
      case CalcType.REALTY:
        yield put(generateRealtyActions.failure());
        break;
    }
  }
}

function* generateOffer({
  payload
}:
  | ReturnType<typeof generateVehicleOfferActions.request>
  | ReturnType<typeof generateTravelOfferActions.request>
  | ReturnType<typeof generateRealtyOfferActions.request>) {
  try {
    const response: AxiosResponse<Blob> = yield call(api.generateOffer, payload);
    openBlobFile(response, true);
    switch (payload.type) {
      case OfferType.MTPL:
      case OfferType.CRASH:
      case OfferType.MTPL_CRASH:
        yield put(generateVehicleOfferActions.success());
        break;
      case OfferType.TRAVEL:
        yield put(generateTravelOfferActions.success());
        break;
      case OfferType.REALTY:
      case OfferType.REALTY_RISKS:
        yield put(generateRealtyOfferActions.success());
        break;
    }
  } catch (error) {
    switch (payload.type) {
      case OfferType.MTPL:
      case OfferType.CRASH:
      case OfferType.MTPL_CRASH:
        yield put(generateVehicleOfferActions.failure());
        break;
      case OfferType.TRAVEL:
        yield put(generateTravelOfferActions.failure());
        break;
      case OfferType.REALTY:
      case OfferType.REALTY_RISKS:
        yield put(generateRealtyOfferActions.failure());
        break;
    }
  }
}

function* downloadCardReader() {
  try {
    const response: AxiosResponse<Blob> = yield call(api.downloadCardReader);
    openBlobFile(response, true);
    yield put(downloadCardReaderActions.success());
  } catch (error) {
    yield put(downloadCardReaderActions.failure());
  }
}

function* filterCalcNotices({ payload }: ReturnType<typeof filterCalcNoticesActions.request>) {
  try {
    const response: AxiosResponse<DashboardNoticeFilterPageResult> = yield call(
      dashboardApi.filterDashboardNotices,
      payload
    );
    yield put(filterCalcNoticesActions.success(response.data));
  } catch (error) {
    yield put(filterCalcNoticesActions.failure());
  }
}

function* handleFailureOfLoadContractToCalculator() {
  messageUtils.errorNotification({
    message: t("common.error"),
    description: t("calc.helpers.loadDataError"),
    key: "loadCalcDataError"
  });
  yield put(replace(window.location.pathname));
  yield put(loadContractToCalculatorActions.failure());
}

function* loadContractToCalculator({ payload }: ReturnType<typeof loadContractToCalculatorActions.request>) {
  try {
    const responseContract = ((yield call(contractApi.getContract, payload)) as AxiosResponse<Contract>).data;

    if (!isInsuranceContract(responseContract)) {
      yield handleFailureOfLoadContractToCalculator();
      return;
    }

    const calcGenData = prepareGenDataForCalculatorFromContract(responseContract);

    if (!calcGenData) {
      yield handleFailureOfLoadContractToCalculator();
      return;
    }

    if (isVehicleGenData(calcGenData)) {
      yield put(setVehicleInitialGenDataAction(calcGenData));
    } else if (isRealtyGenData(calcGenData)) {
      yield put(setRealtyInitialGenDataAction(calcGenData));
    } else if (isTravelGenData(calcGenData)) {
      yield put(setTravelInitialGenDataAction(calcGenData));
    }

    yield put(loadContractToCalculatorActions.success());
  } catch (error) {
    yield put(loadContractToCalculatorActions.failure());
    yield put(replace(window.location.pathname));
  }
}

export function* calcsSaga() {
  yield takeLatest(calculateVehicleActions.request, calculate);
  yield takeLatest(generateVehicleActions.request, generate);
  yield takeLatest(generateVehicleOfferActions.request, generateOffer);

  yield takeLatest(calculateTravelActions.request, calculate);
  yield takeLatest(generateTravelActions.request, generate);
  yield takeLatest(generateTravelOfferActions.request, generateOffer);

  yield takeLatest(calculateRealtyActions.request, calculate);
  yield takeLatest(generateRealtyActions.request, generate);
  yield takeLatest(generateRealtyOfferActions.request, generateOffer);

  yield takeLatest(downloadCardReaderActions.request, downloadCardReader);
  yield takeLatest(filterCalcNoticesActions.request, filterCalcNotices);

  yield takeLatest(loadContractToCalculatorActions.request, loadContractToCalculator);
}
