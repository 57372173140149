import { Moment } from "moment";
import { toMoment } from "../../../../common/utils/formUtils";
import { CreateUpdateContractClient, LegalClient, NaturalClient } from "../../../client/types";
import { createUpdateContractClientToClient } from "../../../client/utils";
import { CalcType } from "../../enums";
import { NearbyBuildingType } from "./enums";
import { RealtyCalc, RealtyFormClients, RealtyGen, RealtyGenForm } from "./types";

export const createRealtyFormClientsObjectFromCalcData = (calcData: RealtyCalc): RealtyFormClients => ({
  policyHolder: calcData.clientsData?.policyHolderIndex
    ? createUpdateContractClientToClient(
        calcData.clientsData.clients?.[calcData.clientsData?.policyHolderIndex] as CreateUpdateContractClient
      )
    : undefined,
  insured: undefined,
  representative: undefined,
  vinculation: undefined
});

export const createRealtyFormClientsObjectFromGenData = (genData: RealtyGen): RealtyFormClients => ({
  policyHolder: genData.clientsData.policyHolderIndex
    ? createUpdateContractClientToClient(
        genData.clientsData.clients?.[genData.clientsData.policyHolderIndex] as CreateUpdateContractClient
      )
    : undefined,
  insured:
    genData.clientsData.insuredClientIndex !== genData.clientsData.policyHolderIndex
      ? (createUpdateContractClientToClient(
          genData.clientsData.clients?.[genData.clientsData.insuredClientIndex] as CreateUpdateContractClient
        ) as NaturalClient)
      : undefined,
  representative: genData.clientsData.representativeIndex
    ? (createUpdateContractClientToClient(
        genData.clientsData.clients?.[genData.clientsData.representativeIndex] as CreateUpdateContractClient
      ) as NaturalClient)
    : undefined,
  vinculation: genData.clientsData.vinculationClientIndex
    ? (createUpdateContractClientToClient(
        genData.clientsData.clients?.[genData.clientsData.vinculationClientIndex] as CreateUpdateContractClient
      ) as LegalClient)
    : undefined
});

export const createRealtyCalcObjectFromCalcData = (calcData: RealtyCalc, clients: RealtyFormClients): RealtyCalc => ({
  ...calcData,
  clientsData: {
    ...calcData.clientsData,
    policyHolderIdentifier: clients.policyHolder?.identifier
  },
  generalInsuranceData: {
    ...calcData.generalInsuranceData,
    effectiveBeginningDate: calcData.generalInsuranceData.effectiveBeginningDate
      ? (toMoment(calcData.generalInsuranceData.effectiveBeginningDate) as Moment)
      : ""
  }
});

export const createRealtyCalcObjectFromGenData = (genData: RealtyGen, clients: RealtyFormClients): RealtyCalc => ({
  type: CalcType.REALTY,
  clientsData: {
    policyHolderIdentifier: clients.policyHolder?.identifier
  },
  generalBuildingData: genData.generalBuildingData,
  houseOrRecreationalBuildingData: genData.houseOrRecreationalBuildingData,
  apartmentData: genData.apartmentData,
  realtyInsuranceData: genData.realtyInsuranceData,
  householdInsuranceData: genData.householdInsuranceData,
  liabilityInsuranceData: genData.liabilityInsuranceData,
  generalInsuranceData: {
    ...genData.generalInsuranceData,
    effectiveBeginningDate: genData.generalInsuranceData.effectiveBeginningDate
      ? (toMoment(genData.generalInsuranceData.effectiveBeginningDate) as Moment)
      : ""
  }
});

export const createRealtyGenFormDataObject = (genData: RealtyGen, clients: RealtyFormClients): RealtyGenForm => ({
  clientsData: {
    policyHolderIsAlsoInsured: genData.clientsData.policyHolderIndex === genData.clientsData.insuredClientIndex,
    policyHolderEmail: genData.clientsData.policyHolderEmail,
    policyHolderPhone: genData.clientsData.policyHolderPhone,
    policyHolderMarketingConsent: genData.clientsData.policyHolderMarketingConsent,
    policyHolderIdentityCardNumber: (clients.policyHolder as NaturalClient)?.identityCardNumber,
    insuredClientIdentifier: clients.insured?.identifier,
    representativeIdentifier: clients.representative?.identifier,
    representativeFunction: genData.clientsData.representativeFunction,
    vinculationClientIdentifier: clients.vinculation?.identifier
  },
  generalBuildingData: {
    placeOfInsurance: genData.generalBuildingData.placeOfInsurance,
    cadastralArea: genData.generalBuildingData.cadastralArea
  },
  apartmentData: genData.apartmentData ? { numberOfFloors: genData.apartmentData.numberOfFloors } : undefined,
  realtyInsuranceData: genData.realtyInsuranceData
    ? { garageReinsurance: genData.realtyInsuranceData.garageReinsurance }
    : undefined,
  householdInsuranceData: genData.householdInsuranceData
    ? { equipmentReinsurances: genData.householdInsuranceData.equipmentReinsurances || [] }
    : undefined,
  generalInsuranceData: {
    signCity: genData.generalInsuranceData.signCity ?? "",
    anotherInsuranceCompanyId: genData.generalInsuranceData.anotherInsuranceCompanyId,
    anotherInsuranceCompanyEnabled: !!genData.generalInsuranceData.anotherInsuranceCompanyId,
    inspectionExecuted: !!genData.generalInsuranceData.inspectionExecuted,
    agentIsClosePersonToClient: !!genData.generalInsuranceData.agentIsClosePersonToClient,
    loanNumber: genData.generalInsuranceData.loanNumber,
    crossSelling: {
      unionHealthContractNumber: genData.generalInsuranceData.crossSelling?.unionHealthContractNumber
    }
  },
  financialMediationData: genData.financialMediationData
});

export const NEARBY_BUILDING_TYPES_WITH_AREA = [
  NearbyBuildingType.DETACHED_GARAGE,
  NearbyBuildingType.GARDEN,
  NearbyBuildingType.FARM_BUILDING,
  NearbyBuildingType.WORKSHOP,
  NearbyBuildingType.ARBOUR,
  NearbyBuildingType.GARDEN_HOUSE,
  NearbyBuildingType.WINTER_GARDEN,
  NearbyBuildingType.GREENHOUSE,
  NearbyBuildingType.UNDERGROUND_CELLAR,
  NearbyBuildingType.SAUNA,
  NearbyBuildingType.TENNIS_COURT,
  NearbyBuildingType.PAVEMENT,
  NearbyBuildingType.OTHER
];

export const NEARBY_BUILDING_TYPES_WITH_VOLUME = [
  NearbyBuildingType.BUILT_SWIMMING_POOL,
  NearbyBuildingType.BUILT_SWIMMING_POOL_WITH_ROOF
];
