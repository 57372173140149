import { Alert, Col, Divider, Row, Table } from "antd";
import { ColumnsType, TablePaginationConfig } from "antd/lib/table";
import { FilterValue, SorterResult, SortOrder, TableCurrentDataSource } from "antd/lib/table/interface";
import moment from "moment";
import { useState } from "react";
import { useSelector } from "react-redux";
import { generatePath, Link } from "react-router-dom";
import t from "../../../../../app/i18n";
import ActionTextIcon from "../../../../../common/components/icons/ActionTextIcon";
import DaysCountTag from "../../../../../common/components/tags/DaysCountTag";
import Ellipsis from "../../../../../common/components/views/Ellipsis";
import { TableSizes } from "../../../../../common/constants";
import { ExportFileType } from "../../../../../common/enums";
import ComponentWithPermission from "../../../../../common/security/authorization/ComponentWithPermission";
import { Permission } from "../../../../../common/security/authorization/enums";
import { RootState } from "../../../../../common/types";
import {
  formatAgentName,
  formatLocaleBruttoPoints,
  formatLocaleCurrency,
  formatLocaleDate,
  formatLocaleDateTime
} from "../../../../../common/utils/formatUtils";
import { orderDirectionToSortOrder, toMoment } from "../../../../../common/utils/formUtils";
import { paginationTableProps, tableStandardProps } from "../../../../../common/utils/utils";
import { selectHasPermissions, selectUserAccount } from "../../../../auth/ducks";
import ClientTypeTagWithName from "../../../../client/components/tags/ClientTypeTagWithName";
import { UserAccount } from "../../../../user/types";
import { changeContractVerificationStatusActions } from "../../../ducks";
import { ContractOrderByColumn, ContractVerificationStatus, ContractView } from "../../../enums";
import { CONTRACT_ROUTE_PATHS } from "../../../paths";
import { ContractFilterPageResult, ContractList } from "../../../types";
import { isAgentOnContract } from "../../../utils";
import ContractAmountInfoPopover from "../../ContractAmountInfoPopover";
import ContractStatusTag from "../../ContractStatusTag";
import ContractVerificationStatusTag from "../../ContractVerificationStatusTag";
import ContractChangeVerificationStatusForm from "../../forms/ContractChangeVerificationStatusForm";

interface Props {
  contractsPage: ContractFilterPageResult;
  onTableChange: (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | undefined | null>,
    sorter: SorterResult<ContractList> | SorterResult<ContractList>[],
    extra: TableCurrentDataSource<ContractList>
  ) => void;
  onVerificationStatusChange: typeof changeContractVerificationStatusActions.request;
  onExportClick: (exportFileType: ExportFileType) => void;
}

const ContractListTableView = ({ contractsPage, ...props }: Props) => {
  const userAccount = useSelector<RootState, UserAccount | undefined>(selectUserAccount);
  const hasCommissionsPermission = useSelector<RootState, boolean>(state =>
    selectHasPermissions(Permission.COMMISSIONS)(state)
  );

  const [contractForVerificationForm, setContractForVerificationForm] = useState<ContractList>();

  const getSortOrder = (column: ContractOrderByColumn): SortOrder | undefined => {
    return contractsPage.orderBy?.indexOf(column)
      ? orderDirectionToSortOrder(contractsPage.orderDirections?.[contractsPage.orderBy.indexOf(column)])
      : undefined;
  };

  const columns: ColumnsType<ContractList> = [];

  if (contractsPage.report === ContractView.INTERNAL_GENERIC_REPORT) {
    columns.push({
      key: "createdAt",
      title: t("contract.attrs.createdAt"),
      width: 160,
      dataIndex: ContractOrderByColumn.CREATED_AT,
      sorter: true,
      defaultSortOrder: getSortOrder(ContractOrderByColumn.CREATED_AT),
      render: (_, record) => formatLocaleDateTime(record.createdAt)
    });
  }

  if (contractsPage.report === ContractView.INTERNAL_ANNIVERSARY_DATE_REPORT) {
    columns.push({
      key: "lastContractCancellationOrContactClientDate",
      title: (
        <>
          {t("contract.attrs.lastContractCancellationDate")}
          <br />
          {t("contract.attrs.contactClientDate")}
        </>
      ),
      width: 180,
      dataIndex: ContractOrderByColumn.LAST_CONTRACT_CANCELLATION_OR_CONTACT_CLIENT_DATE,
      sorter: true,
      defaultSortOrder: getSortOrder(ContractOrderByColumn.LAST_CONTRACT_CANCELLATION_OR_CONTACT_CLIENT_DATE),
      render: (_, record) => {
        const date = record.lastContractCancellationDate || record.contactClientDate;
        const days = date && toMoment(date)?.startOf("day").diff(moment().startOf("day"), "days");
        if (!days) {
          return undefined;
        }

        return (
          <>
            <DaysCountTag days={days} />
            {formatLocaleDate(date)}
          </>
        );
      }
    });
  }

  if (
    contractsPage.report === ContractView.INTERNAL_GENERIC_REPORT ||
    contractsPage.report === ContractView.INTERNAL_PREDICTED_COMMISSIONS_REPORT ||
    contractsPage.report === ContractView.INTERNAL_UNPAID_REPORT
  ) {
    columns.push({
      key: "effectiveBeginningOrSignDate",
      title: (
        <>
          {t("contract.attrs.effectiveBeginningDateShort")}
          <br />
          {t("contract.attrs.signDate")}
        </>
      ),
      width: 140,
      dataIndex: ContractOrderByColumn.EFFECTIVE_BEGINNING_OR_SIGN_DATE,
      sorter: true,
      defaultSortOrder: getSortOrder(ContractOrderByColumn.EFFECTIVE_BEGINNING_OR_SIGN_DATE),
      render: (_, record) => formatLocaleDate(record.effectiveBeginningDate || record.signDate)
    });
  }

  columns.push(
    {
      key: "contractNumbers",
      title: t("contract.attrs.contractNumber"),
      width: 140,
      render: (_, record) => (
        <>
          {record.contractNumber && (
            <>
              <Ellipsis tooltip={record.contractNumber}>
                <Link className="no-link-color" to={generatePath(CONTRACT_ROUTE_PATHS.detail.to, { id: record.id })}>
                  {record.contractNumber}
                </Link>
              </Ellipsis>
              <br />
            </>
          )}
          {record.secondaryContractNumber && (
            <Ellipsis tooltip={record.secondaryContractNumber}>
              <Link className="no-link-color" to={generatePath(CONTRACT_ROUTE_PATHS.detail.to, { id: record.id })}>
                {record.secondaryContractNumber}
              </Link>
            </Ellipsis>
          )}
        </>
      )
    },
    {
      key: "client",
      title: t("contract.attrs.client"),
      width: 180,
      render: (_, record) => <ClientTypeTagWithName client={record.policyHolder} ellipsis showLink />
    },
    {
      key: "institution",
      title: t("contract.attrs.institution"),
      width: 120,
      render: (_, record) => <Ellipsis>{record.institution.name}</Ellipsis>
    },
    {
      key: "product",
      title: t("contract.attrs.product"),
      width: 160,
      render: (_, record) => <Ellipsis>{record.product.name}</Ellipsis>
    }
  );

  if (
    contractsPage.report === ContractView.INTERNAL_GENERIC_REPORT ||
    contractsPage.report === ContractView.INTERNAL_ANNIVERSARY_DATE_REPORT ||
    contractsPage.report === ContractView.INTERNAL_UNPAID_REPORT
  ) {
    columns.push(
      {
        key: "gainerRecords",
        title: t("contract.attrs.gainerRecords._label"),
        width: 160,
        render: (_, record) => {
          const today = moment();

          const gainerRecord =
            record.gainerRecords?.length === 1 || today.isSameOrBefore(toMoment(record.gainerRecords?.[0]?.startDate))
              ? record.gainerRecords?.[0]
              : record.gainerRecords?.find(
                  gainerRecord =>
                    toMoment(gainerRecord.startDate)?.isSameOrBefore(today, "day") &&
                    (!gainerRecord.endDate || toMoment(gainerRecord.endDate)?.isSameOrAfter(today, "day"))
                );
          return (
            <Ellipsis>
              {[
                gainerRecord?.gainer1,
                gainerRecord?.gainer2,
                gainerRecord?.gainer3,
                gainerRecord?.gainer4,
                gainerRecord?.gainer5
              ]
                .filter(gainer => !!gainer)
                .map(gainer => formatAgentName(gainer, false))
                .join(", ")}
            </Ellipsis>
          );
        }
      },
      {
        key: "annualPremiumOrApprovedAmount",
        title: <ContractAmountInfoPopover />,
        width: 130,
        align: "right",
        dataIndex: ContractOrderByColumn.ANNUAL_PREMIUM_OR_APPROVED_AMOUNT,
        sorter: true,
        defaultSortOrder: getSortOrder(ContractOrderByColumn.ANNUAL_PREMIUM_OR_APPROVED_AMOUNT),
        render: (_, record) =>
          formatLocaleCurrency(
            record.annualPremium || record.approvedAmount || record.targetAmount || record.tradeAmount
          )
      }
    );
  }

  if (contractsPage.report === ContractView.INTERNAL_PREDICTED_COMMISSIONS_REPORT) {
    columns.push({
      key: "predictedCommissions",
      title: (
        <>
          <Row>
            <Col span={24} className="center-align">
              {t("contract.attrs.predictedCommissions._label")}
            </Col>
          </Row>
          <Row>
            <Col span={12}>{t("contract.attrs.predictedCommissions.gainerIds")}</Col>
            <Col span={6} className="right-align">
              {t("contract.attrs.predictedCommissions.commissionAmount")}
            </Col>
            <Col span={6} className="right-align">
              {t("contract.attrs.predictedCommissions.bruttoPointAmount")}
            </Col>
          </Row>
        </>
      ),
      width: 450,
      render: (_, record) =>
        record.predictedCommission?.canBeCalculated &&
        (hasCommissionsPermission || record.predictedCommission.calculatedWithoutErrors) &&
        record.predictedCommission.calculatedCommissions
          .filter(pcc => pcc.individualBruttoPoints)
          .map(pcc => (
            <Row key={pcc.id}>
              <Col span={12} className="predicted-commissions-data-cell">
                <Ellipsis>{formatAgentName(pcc.gainer)}</Ellipsis>
              </Col>
              {!!pcc.errors.length ? (
                <Col span={12} className="predicted-commissions-data-cell">
                  <Alert
                    type="error"
                    showIcon
                    message={<Ellipsis>{pcc.errors.flatMap(e => e.description).join(" ")}</Ellipsis>}
                    className="predicted-commission-errors-alert"
                  />
                </Col>
              ) : (
                <>
                  <Col span={6} className="predicted-commissions-data-cell right-align">
                    {formatLocaleCurrency(pcc.commissionAmount)}
                  </Col>
                  <Col span={6} className="predicted-commissions-data-cell right-align">
                    {formatLocaleBruttoPoints(pcc.bruttoPointAmount)}
                  </Col>
                </>
              )}
            </Row>
          ))
    });
  }

  if (contractsPage.report === ContractView.INTERNAL_ANNIVERSARY_DATE_REPORT) {
    columns.push({
      key: "insurancePeriodEndOrFixationAnniversaryDate",
      title: (
        <>
          {t("contract.attrs.insurancePeriodEndDate")}
          <br />
          {t("contract.attrs.fixationAnniversaryDate")}
        </>
      ),
      width: 130,
      align: "right",
      dataIndex: ContractOrderByColumn.INSURANCE_PERIOD_END_OR_FIXATION_ANNIVERSARY_DATE,
      sorter: true,
      defaultSortOrder: getSortOrder(ContractOrderByColumn.INSURANCE_PERIOD_END_OR_FIXATION_ANNIVERSARY_DATE),
      render: (_, record) => formatLocaleDate(record.insurancePeriodEndDate || record.fixationAnniversaryDate)
    });
  }

  if (contractsPage.report === ContractView.INTERNAL_UNPAID_REPORT) {
    columns.push(
      {
        key: "amountOwed",
        title: t("contract.attrs.amountOwed"),
        width: 110,
        align: "right",
        dataIndex: ContractOrderByColumn.AMOUNT_OWED,
        sorter: true,
        defaultSortOrder: getSortOrder(ContractOrderByColumn.AMOUNT_OWED),
        render: (_, record) => <span className="highlighted-text">{formatLocaleCurrency(record.amountOwed)}</span>
      },
      {
        key: "paidUntilDate",
        title: t("contract.attrs.paidUntilDate"),
        width: 120,
        dataIndex: ContractOrderByColumn.PAID_UNTIL_DATE,
        sorter: true,
        defaultSortOrder: getSortOrder(ContractOrderByColumn.PAID_UNTIL_DATE),
        render: (_, record) => formatLocaleDate(record.paidUntilDate)
      }
    );
  }

  columns.push({
    key: "status",
    title: (
      <>
        {t("contract.enums.status._label")}
        <br />
        {t("contract.enums.verificationStatus._label")}
      </>
    ),
    width: 150,
    align: "center",
    render: (_, record) =>
      record.status && record.verificationStatus ? (
        <>
          <ContractStatusTag status={record.status} style={{ marginRight: 0 }} />
          <br />
          <ContractVerificationStatusTag
            status={record.verificationStatus}
            note={record.verificationNote}
            style={{ marginTop: "6px", marginRight: 0 }}
            onSendClick={
              (record.verificationStatus === ContractVerificationStatus.TYPED ||
                record.verificationStatus === ContractVerificationStatus.INTERNAL_INTERVENTION ||
                record.verificationStatus === ContractVerificationStatus.EXTERNAL_INTERVENTION) &&
              userAccount &&
              isAgentOnContract(userAccount, record)
                ? () => setContractForVerificationForm(record)
                : undefined
            }
          />
        </>
      ) : undefined
  });

  columns.push({
    key: "actions",
    align: "right",
    fixed: "right",
    width: 90,
    render: (_, record) => (
      <ActionTextIcon
        path={generatePath(CONTRACT_ROUTE_PATHS.detail.to, { id: record.id })}
        icon="eye"
        color="blue"
        text={t("common.show")}
      />
    )
  });

  return (
    <>
      {(contractsPage.report === ContractView.INTERNAL_GENERIC_REPORT ||
        contractsPage.report === ContractView.INTERNAL_ANNIVERSARY_DATE_REPORT) && (
        <ComponentWithPermission permissions={[Permission.CONTRACT_EXPORT]}>
          <div className={contractsPage.totalElementsCount ? "table-header-export-actions" : "margin-bottom-small"}>
            <ActionTextIcon
              icon="download"
              color="blue"
              text={t("common.exportXlsx")}
              onClick={() => props.onExportClick(ExportFileType.XLSX)}
            />

            <Divider type="vertical" />

            <ActionTextIcon
              icon="download"
              color="green"
              text={t("common.exportCsv")}
              onClick={() => props.onExportClick(ExportFileType.CSV)}
            />
          </div>
        </ComponentWithPermission>
      )}

      <Table<ContractList>
        {...tableStandardProps()}
        columns={columns}
        scroll={{ x: TableSizes.HUGE }}
        dataSource={contractsPage.pageData}
        pagination={{
          ...paginationTableProps,
          current: (contractsPage.pageIndex ?? 0) + 1,
          pageSize: contractsPage.pageSize,
          total: contractsPage.totalElementsCount
        }}
        showSorterTooltip={false}
        onChange={props.onTableChange}
      />

      <ContractChangeVerificationStatusForm
        open={!!contractForVerificationForm}
        contract={contractForVerificationForm}
        viewType="sendToBackoffice"
        onSubmit={props.onVerificationStatusChange}
        onFormCancel={() => setContractForVerificationForm(undefined)}
      />
    </>
  );
};

export default ContractListTableView;
