import { FormInstance } from "antd/lib/form";
import moment from "moment/moment";
import t, { DEFAULT_LOCALE } from "../../../app/i18n";
import { HttpStatus } from "../../../common/constants";
import { ErrorResponse } from "../../../common/types";
import { showErrorResponseNotification } from "../../../common/utils/apiUtils";
import { setErrorsToForm } from "../../../common/utils/formUtils";
import messageUtils from "../../../common/utils/messageUtils";
import { ClientFormType, ClientType } from "../../client/enums";
import { LegalClient, NaturalClient, SelfEmployedClient } from "../../client/types";
import {
  getCreateUpdateContractClientFromClient,
  isLegalClient,
  processClientsDataViolations
} from "../../client/utils";
import {
  GenericInsurance,
  InsuranceContract,
  LifeInsurance,
  RealtyInsurance,
  TravelInsurance,
  VehicleInsurance
} from "../../contract/types";
import { isTravelOrRealtyOrVehicleInsurance } from "../../contract/utils";
import { CalcDraft } from "../drafts/types";
import { CalcType, ClientExperience } from "../enums";
import { CalcRecord, RealtyCalcRecord, TravelCalcRecord, VehicleCalcRecord } from "../records/types";
import { RealtyCalcDraft, RealtyGen } from "./realty/types";
import { TravelCalcDraft, TravelGen } from "./travel/types";
import {
  CalcResponse,
  CalcResult,
  CalcResultData,
  CalculatorsGen,
  GenFinancialMediationData,
  GenResponseViolations,
  StepInfo
} from "./types";
import { AnimalReinsuranceAmount } from "./vehicle/enums";
import { VehicleCalcDraft, VehicleGen } from "./vehicle/types";

export const DEFEND_D2C_COVERAGE = "D2C";

export const sortAndGroupCalcResults = <T extends CalcResultData>(calcResponse: CalcResponse<T>): CalcResult<T>[][] => {
  let sortedResults: CalcResult<T>[][] = [];

  calcResponse.results.forEach(result => {
    const index = sortedResults.findIndex(
      items => items[0]?.insuranceInstitution.id === result.insuranceInstitution.id
    );
    if (index === -1) {
      sortedResults.push([result as CalcResult<T>]);
    } else {
      sortedResults[index]?.push(result as CalcResult<T>);
    }
  });

  const rowsWithoutError: CalcResult<T>[][] = [];
  const rowsWithError: CalcResult<T>[][] = [];
  sortedResults.forEach(resultsRow => {
    if (resultsRow.some(result => result.error)) {
      rowsWithError.push(resultsRow);
    } else {
      rowsWithoutError.push(resultsRow);
    }
  });

  sortResultGroupsByInstitutionName<T>(rowsWithoutError);
  sortResultGroupsByInstitutionName<T>(rowsWithError);
  sortedResults = [...rowsWithoutError, ...rowsWithError];

  sortResultsWithinGroupsByCoverageIndex<T>(sortedResults);

  return sortedResults;
};

export const checkIfStepsAreSuccessful = <T>(steps: StepInfo<T>[], ...checkedSteps: T[]): boolean => {
  return checkedSteps.every(checkedStep => steps.some(step => step.step === checkedStep && step.successful));
};

export const filterApplicableResults = <T extends CalcResultData = CalcResultData>(
  results: CalcResult<T>[]
): CalcResult<T>[] => {
  return results.filter(result => isApplicableResult(result));
};

export const isApplicableResult = <T extends CalcResultData = CalcResultData>(result: CalcResult<T>): boolean => {
  return !result.data || !result.data.notApplicable;
};

export const filterApplicableSuccessResults = <T extends CalcResultData = CalcResultData>(
  results: CalcResult<T>[]
): CalcResult<T>[] => {
  return results.filter(result => isApplicableSuccessResult(result));
};

export const isApplicableSuccessResult = <T extends CalcResultData = CalcResultData>(
  result: CalcResult<T>
): boolean => {
  return result.data && !result.data.notApplicable;
};

export const sortResultGroupsByInstitutionName = <T extends CalcResultData>(resultGroups: CalcResult<T>[][]): void => {
  resultGroups.sort((a, b) => {
    const institutionNameA = a[0]?.insuranceInstitution.name ?? "";
    const institutionNameB = b[0]?.insuranceInstitution.name ?? "";

    return institutionNameA.localeCompare(institutionNameB, DEFAULT_LOCALE, {
      sensitivity: "accent"
    });
  });
};

export const sortResultGroupsByAnnualPremium = <T extends CalcResultData>(resultGroups: CalcResult<T>[][]): void => {
  resultGroups.sort((a, b) => {
    const result1 = a.find(result => result.data && !result.data.notApplicable);
    const result2 = b.find(result => result.data && !result.data.notApplicable);
    return !result1 ? 1 : !result2 ? -1 : result1.data.annualPremium - result2.data.annualPremium;
  });
};

export const sortResultsWithinGroupsByCoverageIndex = <T extends CalcResultData>(
  resultGroups: CalcResult<T>[][]
): void => {
  for (let index = 0; index < resultGroups.length; index++) {
    // @ts-ignore
    resultGroups[index] = resultGroups[index]?.sort((a, b) => a.coverageOrderIndex - b.coverageOrderIndex);
  }
};

export const processGenResultError = (
  error: ErrorResponse,
  clientsIndicesMap: Map<ClientFormType, number>,
  form: FormInstance,
  translationRootPath: string
): GenResponseViolations | undefined => {
  if (error.status === HttpStatus.UNPROCESSABLE_ENTITY && error.violations) {
    const clientsViolations = processClientsDataViolations(clientsIndicesMap, "clientsData.clients", error.violations);
    const notificationViolations = error.violations.filter(
      violation => !violation.fieldPath.startsWith("clientsData.clients")
    );

    if (clientsViolations.size > 0) {
      notificationViolations.unshift({
        fieldPath: "clientsData._label",
        errors: [t("calc.validations.clientsViolationError")],
        fieldValue: undefined
      });
    }

    setErrorsToForm(form, translationRootPath, error.violations);

    return { clientsViolations, notificationViolations };
  }

  showErrorResponseNotification(error);
  return undefined;
};

export const resolveClientFormTypeIdentifierName = (type: ClientFormType): string => {
  switch (type) {
    case ClientFormType.INSURED:
      return "insuredClientIdentifier";
    case ClientFormType.POLICY_HOLDER:
      return "policyHolderIdentifier";
    case ClientFormType.REPRESENTATIVE:
      return "representativeIdentifier";
    case ClientFormType.VINCULATION:
      return "vinculationClientIdentifier";
    case ClientFormType.HOLDER:
      return "holderIdentifier";
    case ClientFormType.OWNER:
      return "ownerIdentifier";
    case ClientFormType.CO_DEBTOR:
      return "coDebtorIdentifier";
    default:
      return "";
  }
};

const getDefaultGenFinancialMediationData = (policyHolderType: ClientType): Partial<GenFinancialMediationData> => ({
  clientExperience:
    policyHolderType === ClientType.SELF_EMPLOYED || policyHolderType === ClientType.LEGAL
      ? ClientExperience.SUFFICIENT
      : undefined,
  otherClientRequirements: undefined,
  recommendedResult: undefined,
  recommendationReason: undefined,
  additionalClientStatement: undefined,
  additionalSuitabilityStatement: undefined
});

const prepareClientsData = (clients: (NaturalClient | SelfEmployedClient | LegalClient)[]) => {
  return clients.map(client => {
    const clientData = getCreateUpdateContractClientFromClient(client);

    if (isLegalClient(client)) {
      return { ...clientData };
    }

    return {
      ...clientData,
      client: {
        ...clientData.client,
        identityCardNumber: undefined,
        previousIdentityCardNumber: client.identityCardNumber
      }
    };
  });
};

export const prepareGenDataForCalculatorFromContract = (contract: InsuranceContract): CalculatorsGen | undefined => {
  const insurance = contract.insurances[0] as
    | TravelInsurance
    | RealtyInsurance
    | VehicleInsurance
    | GenericInsurance
    | LifeInsurance;

  if (!isTravelOrRealtyOrVehicleInsurance(insurance)) {
    messageUtils.errorNotification({
      message: t("common.error"),
      description: t("calc.helpers.loadDataError"),
      key: "loadCalcDataError"
    });

    return undefined;
  } else {
    const calcData = insurance.calcData;

    if (!calcData) {
      messageUtils.errorNotification({
        message: t("common.error"),
        description: t("calc.helpers.loadDataError"),
        key: "loadCalcDataError"
      });

      return undefined;
    }

    let resultCalcData: Record<string, any> = { ...calcData };

    try {
      if (isVehicleGenData(resultCalcData)) {
        resultCalcData = {
          ...resultCalcData,
          generalData: {
            ...resultCalcData.generalData,
            effectiveBeginningDate: moment(),
            mtplInsuranceEffectiveBeginningDate: undefined,
            crossSelling: {
              csobContract: false,
              kooperativaContract: false,
              unionHealthContract: false,
              unionHealthContractNumber: undefined,
              uniqaContract: false,
              uniqaContractNumber: undefined
            }
          },
          clientsData: {
            ...resultCalcData.clientsData,
            policyHolderIndex: contract.policyHolderIndex,
            holderIndex: (contract.insurances[0] as VehicleInsurance).vehicleHolderIndex,
            ownerIndex: (contract.insurances[0] as VehicleInsurance).vehicleOwnerIndex,
            clients: [...prepareClientsData(contract.clients)],
            holderTimeWithoutAccident: undefined,
            holderAccidentsIn1Year: undefined,
            holderAccidentsIn2Years: undefined,
            holderAccidentsIn3Years: undefined,
            holderAccidentsIn4Years: undefined,
            holderAccidentsIn5Years: undefined,
            holderAccidentsIn8Years: undefined,
            holderAccidentsIn10Years: undefined,
            holderAccidentsInAllYears: undefined,
            currentlyInsuredVehicles: undefined
          },
          vehicleData: {
            ...resultCalcData.vehicleData,
            price: undefined,
            generalPrice: undefined,
            buyingPrice: undefined,
            odometer: undefined,
            newVehicle: false,
            registrationCertificateNumber: undefined,
            previousRegistrationCertificateNumber: resultCalcData.vehicleData.registrationCertificateNumber
          },
          financialMediationData: {
            ...getDefaultGenFinancialMediationData(contract.clients[contract.policyHolderIndex]?.type as ClientType),
            recommendedGapComplicityReinsurance: false,
            recommendedGapCoverageLimit: undefined
          },
          reinsurancesData: {
            ...resultCalcData.reinsurancesData,
            animalAmount:
              resultCalcData.reinsurancesData.animalAmount &&
              Object.values(AnimalReinsuranceAmount).includes(resultCalcData.reinsurancesData.animalAmount)
                ? resultCalcData.reinsurancesData.animalAmount
                : undefined
          }
        };
      } else if (isRealtyGenData(resultCalcData)) {
        resultCalcData = {
          ...resultCalcData,
          generalInsuranceData: {
            ...resultCalcData.generalInsuranceData,
            effectiveBeginningDate: moment(),
            crossSelling: {
              csobContracts: undefined,
              unionHealthContract: false,
              unionHealthContractNumber: undefined,
              uniqaContractNumber: undefined,
              premiumContractNumber: undefined
            }
          },
          clientsData: {
            ...resultCalcData.clientsData,
            policyHolderIndex: contract.policyHolderIndex,
            insuredClientIndex: (contract.insurances[0] as RealtyInsurance).insuredClientIndex,
            vinculationClientIndex: (contract.insurances[0] as RealtyInsurance).vinculationClientIndex,
            clients: [...prepareClientsData(contract.clients)]
          },
          financialMediationData: {
            ...getDefaultGenFinancialMediationData(contract.clients[contract.policyHolderIndex]?.type as ClientType),
            ownedByClient: true,
            businessDesc: undefined
          }
        };
      } else if (isTravelGenData(resultCalcData)) {
        resultCalcData = {
          ...resultCalcData,
          generalData: { ...resultCalcData.generalData, effectiveBeginningDate: moment(), effectiveEndDate: undefined },
          clientsData: {
            ...resultCalcData.clientsData,
            policyHolderIndex: contract.policyHolderIndex,
            clients: [...prepareClientsData(contract.clients)]
          },
          cancellationData: {
            ...resultCalcData.cancellationData,
            tripPrice: undefined,
            firstPaymentDate: undefined
          },
          financialMediationData: {
            ...getDefaultGenFinancialMediationData(contract.clients[contract.policyHolderIndex]?.type as ClientType)
          },
          coveragesData: {
            ...resultCalcData.coveragesData,
            cancellationData: {
              ...resultCalcData.coveragesData?.cancellationData,
              tripPrice: undefined,
              firstPaymentDate: undefined
            }
          }
        };
      }

      return resultCalcData as CalculatorsGen;
    } catch (e) {
      messageUtils.errorNotification({
        message: t("common.error"),
        description: t("calc.helpers.loadDataError"),
        key: "loadCalcDataError"
      });
      return undefined;
    }
  }
};

export const isRealtyGenData = (
  calcData: Partial<TravelGen> | Partial<VehicleGen> | Partial<RealtyGen>
): calcData is RealtyGen => {
  return calcData.type === CalcType.REALTY;
};

export const isTravelGenData = (
  calcData: Partial<TravelGen> | Partial<VehicleGen> | Partial<RealtyGen>
): calcData is TravelGen => {
  return calcData.type === CalcType.TRAVEL;
};

export const isVehicleGenData = (
  calcData: Partial<TravelGen> | Partial<VehicleGen> | Partial<RealtyGen>
): calcData is VehicleGen => {
  return (
    calcData.type === CalcType.MTPL ||
    calcData.type === CalcType.MTPL_CRASH ||
    calcData.type === CalcType.GAP ||
    calcData.type === CalcType.CRASH ||
    calcData.type === CalcType.PAS
  );
};

const isVehicleCalcType = (calcType: CalcType): boolean =>
  calcType === CalcType.MTPL ||
  calcType === CalcType.MTPL_CRASH ||
  calcType === CalcType.GAP ||
  calcType === CalcType.CRASH ||
  calcType === CalcType.PAS;

export const isRealtyCalcRecord = (calcRecord: CalcRecord): calcRecord is RealtyCalcRecord =>
  calcRecord.calcType === CalcType.REALTY;

export const isTravelCalcRecord = (calcRecord: CalcRecord): calcRecord is TravelCalcRecord =>
  calcRecord.calcType === CalcType.TRAVEL;

export const isVehicleCalcRecord = (calcRecord: CalcRecord): calcRecord is VehicleCalcRecord =>
  isVehicleCalcType(calcRecord.calcType);

export const isRealtyCalcDraft = (calcDraft: CalcDraft): calcDraft is RealtyCalcDraft =>
  calcDraft.calcType === CalcType.REALTY;

export const isTravelCalcDraft = (calcDraft: CalcDraft): calcDraft is TravelCalcDraft =>
  calcDraft.calcType === CalcType.TRAVEL;

export const isVehicleCalcDraft = (calcDraft: CalcDraft): calcDraft is VehicleCalcDraft =>
  isVehicleCalcType(calcDraft.calcType);
