import { Card } from "antd";
import { useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import t from "../../../app/i18n";
import BackNavigationArrow from "../../../common/components/views/BackNavigationArrow";
import ContentWrapper from "../../../common/modules/wrappers/ContentWrapper";
import DisplayWrapper from "../../../common/modules/wrappers/DisplayWrapper";
import { ActionProps, EntityIdObject, RootState } from "../../../common/types";
import { requests } from "../api";
import InstitutionForm from "../components/forms/InstitutionForm";
import {
  deleteInstitutionActions,
  deleteStateInstitutionDetailAction,
  getInstitutionActions,
  selectInstitutionDetail,
  updateInstitutionActions
} from "../ducks";
import { Institution } from "../types";

interface StateProps {
  institution?: Institution;
}

interface ActionsMap {
  getInstitution: typeof getInstitutionActions.request;
  updateInstitution: typeof updateInstitutionActions.request;
  deleteInstitution: typeof deleteInstitutionActions.request;
  deleteStateInstitutionDetail: typeof deleteStateInstitutionDetailAction;
}

const InstitutionDetailContainer = (props: StateProps & ActionProps<ActionsMap>) => {
  const { id } = useParams<EntityIdObject>() as { id: string };

  useEffect(() => {
    if (!props.institution) {
      props.actions.getInstitution({ id });
    }
    return () => {
      props.actions.deleteStateInstitutionDetail();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ContentWrapper>
      <Card
        className="card-box"
        title={
          <BackNavigationArrow>
            <h2>{t("institution.titles.update")}</h2>
          </BackNavigationArrow>
        }
      >
        <DisplayWrapper itemLoaded={!!props.institution} notFoundCheckRequest={requests.GET_INSTITUTION}>
          <InstitutionForm
            institution={props.institution}
            onUpdate={props.actions.updateInstitution}
            onDelete={props.actions.deleteInstitution}
          />
        </DisplayWrapper>
      </Card>
    </ContentWrapper>
  );
};

const mapStateToProps = (state: RootState): StateProps => ({
  institution: selectInstitutionDetail(state)
});

const mapDispatchToProps = (dispatch: Dispatch): ActionProps<ActionsMap> => ({
  actions: bindActionCreators(
    {
      getInstitution: getInstitutionActions.request,
      updateInstitution: updateInstitutionActions.request,
      deleteInstitution: deleteInstitutionActions.request,
      deleteStateInstitutionDetail: deleteStateInstitutionDetailAction
    },
    dispatch
  )
});

export default connect<StateProps, ActionProps<ActionsMap>, {}, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(InstitutionDetailContainer);
