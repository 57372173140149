import { Col, Divider, Row, Tooltip } from "antd";
import t from "../../../../app/i18n";
import ActionTextIcon from "../../../../common/components/icons/ActionTextIcon";
import { ClientFormStage } from "../../enums";

interface Props {
  label: string;
  stage?: ClientFormStage;
  optional?: boolean;
  deleteActionType?: "delete" | "deselect";
  onCreateClick: () => void;
  onUpdateClick: () => void;
  onDeleteClick?: () => void;
}

const ClientActionsFormLabel = ({ label, stage, optional, deleteActionType, ...props }: Props) => {
  let actions = undefined;
  switch (stage) {
    case ClientFormStage.EMPTY:
      actions = props.onDeleteClick && (
        <Tooltip title={t("client.actions.deleteClient")}>
          <span>
            <ActionTextIcon icon="close" color="red" size="large" onClick={props.onDeleteClick} />
          </span>
        </Tooltip>
      );
      break;
    case ClientFormStage.NEW:
      actions = (
        <Tooltip title={t("client.actions.createClient")}>
          <span>
            <ActionTextIcon icon="user-add" color="green" size="large" onClick={props.onCreateClick} />
          </span>
        </Tooltip>
      );
      break;
    case ClientFormStage.EXISTING:
    case ClientFormStage.SELECTED:
      actions = (
        <>
          <Tooltip title={t("client.actions.updateClient")}>
            <span>
              <ActionTextIcon icon="edit" color="blue" size="large" onClick={props.onUpdateClick} />
            </span>
          </Tooltip>
          {props.onDeleteClick && (
            <>
              <Divider type="vertical" />
              <Tooltip
                title={
                  deleteActionType === "delete" ? t("client.actions.deleteClient") : t("client.actions.deselectClient")
                }
              >
                <span>
                  <ActionTextIcon icon="close" color="red" size="large" onClick={props.onDeleteClick} />
                </span>
              </Tooltip>
            </>
          )}
        </>
      );
      break;
  }

  return (
    <Row>
      <Col span={16} className={!optional ? "required-item" : undefined}>
        {label}
      </Col>
      <Col span={8} className="right-align">
        {actions}
      </Col>
    </Row>
  );
};

export default ClientActionsFormLabel;
