import { green, red } from "@ant-design/colors";
import { Alert, Col, Divider, Row, Statistic } from "antd";
import t from "../../../../../app/i18n";
import AntIcon from "../../../../../common/components/icons/AntIcon";
import { formatLocaleCurrency, formatLocaleDate, formatPhoneNumber } from "../../../../../common/utils/formatUtils";
import { ClientsListDrawerView, createClientsData } from "../../../../client/components/drawers/ClientsListDrawerView";
import { ClientFormType, ClientType } from "../../../../client/enums";
import { InstitutionEnum } from "../../../../institution/enums";
import { TravelInsuranceType } from "../../travel/enums";
import { TravelCalc, TravelCalcResultData, TravelFormClients, TravelGenForm } from "../../travel/types";
import { CalcResult } from "../../types";

export type TravelCalcSummaryViewData = {
  calcData: TravelCalc;
  clients: TravelFormClients;
  formData: TravelGenForm;
  selectedInsurance: CalcResult<TravelCalcResultData>;
};

type Props = {
  data: TravelCalcSummaryViewData;
};

const SPAN_LABEL = 5;
const SPAN_VALUE = 19;

const TravelCalcSummaryView = ({ data: { calcData, clients, formData, selectedInsurance } }: Props) => {
  const axaAssistanceYearFamilyInsurance =
    selectedInsurance.insuranceInstitution.institutionEnum === InstitutionEnum.AXA_ASSISTANCE &&
    calcData.generalData.insuranceType === TravelInsuranceType.YEAR &&
    calcData.discountsData?.familyInsurance &&
    (clients.policyHolder?.type === ClientType.NATURAL || clients.policyHolder);

  return (
    <div>
      <Row>
        <Col flex={1}>
          <Statistic
            className="statistic-small"
            title={t("calc.helpers.summaryData.product")}
            value={t("calc.travel.enums.insuranceType." + calcData.generalData.insuranceType)}
          />
        </Col>

        <Col flex={1}>
          <Statistic
            className="statistic-small"
            title={t("common.insuranceInstitution")}
            value={selectedInsurance.insuranceInstitution.name}
          />
        </Col>

        {selectedInsurance.coverage ? (
          <Col flex={1}>
            <Statistic
              className="statistic-small"
              title={<span style={{ textTransform: "capitalize" }}>{t("calc.travel.sections.coverage")}</span>}
              value={selectedInsurance.coverage}
            />
          </Col>
        ) : undefined}

        <Col flex={1}>
          <Statistic
            className="statistic-small"
            title={t("calc.travel.enums.territorialValidity._label")}
            value={t("calc.travel.enums.territorialValidity." + calcData.generalData.territorialValidity)}
          />
        </Col>

        <Col flex={1}>
          {calcData.generalData.riskGroup ? (
            <Statistic
              className="statistic-small"
              title={t("calc.travel.enums.riskGroup._label")}
              value={t("calc.travel.enums.riskGroup." + calcData.generalData.riskGroup)}
            />
          ) : undefined}
        </Col>
      </Row>
      <Row className="margin-top-small">
        <Col flex={1}>
          <Divider orientation="left">{t("calc.travel.attrs.clientsData._label")}</Divider>
          <ClientsListDrawerView
            clientsData={createClientsData([
              {
                client: clients.policyHolder,
                type: ClientFormType.POLICY_HOLDER
              }
            ])}
          />
        </Col>
      </Row>
      <Row>
        <Col flex={1}>
          <Row>
            <Col span={SPAN_LABEL}>{t("calc.travel.attrs.clientsData.policyHolderPhone")}</Col>
            <Col span={SPAN_VALUE}>{formatPhoneNumber(formData.clientsData.policyHolderPhone)}</Col>
          </Row>
          <Row>
            <Col span={SPAN_LABEL}>{t("calc.travel.attrs.clientsData.policyHolderEmail")}</Col>
            <Col span={SPAN_VALUE}>{formData.clientsData.policyHolderEmail}</Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col flex={1}>
          <Row>
            {axaAssistanceYearFamilyInsurance ? (
              <Alert
                style={{ marginTop: "20px" }}
                type="info"
                showIcon
                message={t("calc.travel.helpers.axaAssistanceYearFamilyInsuranceDesc")}
              />
            ) : (
              <>
                <Col span={SPAN_LABEL}>{t("calc.travel.sections.insuredPersons")}</Col>
                <Col span={SPAN_VALUE}>
                  {formData.clientsData.insuredClients
                    ?.map(client => `${client.firstName} ${client.lastName} (${formatLocaleDate(client.birthDate)})`)
                    .join(", ")}
                </Col>
              </>
            )}
          </Row>
        </Col>
      </Row>

      <Row className="margin-top-small">
        <Col flex={1}>
          <Divider orientation="left">{t("calc.travel.sections.coverages")}</Divider>
          {selectedInsurance.data.appliedCoverages.map(coverage => {
            return (
              <Row key={coverage.type}>
                <Col span={SPAN_LABEL}>{t("contract.enums.insuranceCoverageType." + coverage.type)}</Col>
                <Col span={SPAN_VALUE}>
                  <AntIcon type="check-circle-two-tone" twoToneColor={green[5]} />
                </Col>
              </Row>
            );
          })}
          {selectedInsurance.data.missingCoverages.map(coverage => {
            return (
              <Row key={coverage}>
                <Col span={SPAN_LABEL}> {t("contract.enums.insuranceCoverageType." + coverage)}</Col>
                <Col span={SPAN_VALUE}>
                  <AntIcon type="close-circle-two-tone" twoToneColor={red[5]} />
                </Col>
              </Row>
            );
          })}
        </Col>
      </Row>

      <Row className="margin-top-small">
        <Col flex={1}>
          <Divider orientation="left">{t("calc.travel.results.additionalInfo")}</Divider>
          <Row>
            <Col span={SPAN_LABEL}>{t("calc.travel.attrs.generalData.effectiveBeginningDate")}</Col>
            <Col span={SPAN_VALUE}>{formatLocaleDate(calcData.generalData.effectiveBeginningDate)}</Col>
          </Row>
          {calcData.generalData.effectiveEndDate ? (
            <Row>
              <Col span={SPAN_LABEL}> {t("calc.travel.attrs.generalData.effectiveEndDate")}</Col>
              <Col span={SPAN_VALUE}>{formatLocaleDate(calcData.generalData.effectiveEndDate)}</Col>
            </Row>
          ) : undefined}
          {calcData.cancellationData ? (
            <Row>
              <Col span={SPAN_LABEL}>{t("calc.travel.attrs.cancellationData.tripPrice")}</Col>
              <Col span={SPAN_VALUE}>{formatLocaleCurrency(calcData.cancellationData?.tripPrice)}</Col>
            </Row>
          ) : undefined}
          <Row>
            <Col span={SPAN_LABEL}>
              {t(
                calcData.generalData.insuranceType === TravelInsuranceType.YEAR
                  ? "contract.attrs.annualPremium"
                  : "calc.travel.results.annualPremium"
              )}
            </Col>
            <Col span={SPAN_VALUE}>{formatLocaleCurrency(selectedInsurance.data.annualPremium)}</Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default TravelCalcSummaryView;
