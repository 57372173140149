import { Alert, Card, Col, Collapse, Divider, Row } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import t from "../../../../../app/i18n";
import Ellipsis from "../../../../../common/components/views/Ellipsis";
import { rowGutter } from "../../../../../common/constants";
import { Permission } from "../../../../../common/security/authorization/enums";
import { RootState } from "../../../../../common/types";
import {
  formatAgentName,
  formatLocaleBruttoPoints,
  formatLocaleCurrency,
  formatLocaleDate,
  formatLocalePercentageNumber
} from "../../../../../common/utils/formatUtils";
import { isNumberValue } from "../../../../../common/utils/utils";
import { selectHasPermissions } from "../../../../auth/ducks";
import { Contract, ContractGainerKey, ContractGainerRateKey } from "../../../types";

interface Props {
  contract: Contract;
}

const ContractAgentsSection = ({ contract }: Props) => {
  const hasCommissionsPermission = useSelector<RootState, boolean>(state =>
    selectHasPermissions(Permission.COMMISSIONS)(state)
  );

  return (
    <Card type="inner" className="card-box" title={t("contract.sections.agents")}>
      <Row gutter={rowGutter}>
        <Col span={contract.affiliatePartner ? 16 : 24}>
          <table className="data-table-view">
            <tbody>
              <tr>
                <td>{t("contract.attrs.signer")}:</td>
                <td>{formatAgentName(contract.signer)}</td>
                <td>{t("contract.attrs.manager")}:</td>
                <td>{formatAgentName(contract.manager)}</td>
              </tr>
            </tbody>
          </table>
        </Col>

        {contract.affiliatePartner && (
          <Col span={8} className="right-align">
            <span className="margin-right-tiny">{t("contract.attrs.affiliatePartner")}:</span>
            <span>{contract.affiliatePartner.name}</span>
          </Col>
        )}
      </Row>

      <h4>
        <b>{t("contract.attrs.gainerRecords._label")}:</b>
      </h4>

      <table className="contract-gainer-records-view">
        <tbody>
          {contract.gainerRecords?.map((record, index) => (
            <tr key={record.id}>
              {index > 0 && (
                <>
                  <td>{t("contract.attrs.gainerRecords.startDate") + " " + formatLocaleDate(record.startDate)}</td>
                  <td>
                    {record.endDate &&
                      t("contract.attrs.gainerRecords.endDate") + " " + formatLocaleDate(record.endDate)}
                  </td>
                </>
              )}
              <td>
                {!!record.gainer2
                  ? Array.from({ length: 5 }, (_, i) => i + 1)
                      .filter(i => !!record[`gainer${i}` as ContractGainerKey])
                      .map(
                        i =>
                          formatAgentName(record[`gainer${i}` as ContractGainerKey]) +
                          " - " +
                          formatLocalePercentageNumber(record[`gainer${i}Rate` as ContractGainerRateKey])
                      )
                      .join(", ")
                  : formatAgentName(record.gainer1)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {contract.predictedCommission?.canBeCalculated &&
        (hasCommissionsPermission || contract.predictedCommission.calculatedWithoutErrors) && (
          <>
            <h4>
              <b>{t("contract.attrs.predictedCommissions._label")}:</b>
            </h4>

            <Row>
              <Col xs={24} sm={24} md={24} lg={20} xl={16} xxl={15}>
                <Collapse>
                  <Collapse.Panel
                    key={1}
                    showArrow={false}
                    className="predicted-commissions-collapse"
                    header={contract.predictedCommission.calculatedCommissions
                      .filter(pcc => pcc.individualBruttoPoints)
                      .map(pcc => (
                        <Row key={pcc.id}>
                          <Col span={12} className="predicted-commissions-data-cell">
                            <Ellipsis>{formatAgentName(pcc.gainer)}</Ellipsis>
                          </Col>
                          {!!pcc.errors.length ? (
                            <Col span={12} className="predicted-commissions-data-cell">
                              <Alert
                                type="error"
                                showIcon
                                message={<Ellipsis>{pcc.errors.flatMap(e => e.description).join(" ")}</Ellipsis>}
                                className="predicted-commission-errors-alert"
                              />
                            </Col>
                          ) : (
                            <>
                              <Col span={6} className="predicted-commissions-data-cell right-align">
                                {formatLocaleCurrency(pcc.commissionAmount)}
                              </Col>
                              <Col span={6} className="predicted-commissions-data-cell right-align">
                                {formatLocaleBruttoPoints(pcc.bruttoPointAmount)}
                              </Col>
                            </>
                          )}
                        </Row>
                      ))}
                  >
                    {contract.predictedCommission.calculatedCommissions.map(pcc => (
                      <React.Fragment key={pcc.id}>
                        <Row>
                          <Col span={12} className="predicted-commissions-row-cell left-align">
                            <Ellipsis>{formatAgentName(pcc.gainer)}</Ellipsis>
                          </Col>
                          {!!pcc.errors.length ? (
                            <Col span={12} className="predicted-commissions-data-cell">
                              <Alert
                                type="error"
                                showIcon
                                message={<Ellipsis>{pcc.errors.flatMap(e => e.description).join(" ")}</Ellipsis>}
                                className="predicted-commission-errors-alert"
                              />
                            </Col>
                          ) : (
                            <>
                              <Col span={6} className="predicted-commissions-row-cell right-align">
                                {formatLocaleCurrency(pcc.commissionAmount)}
                              </Col>
                              <Col span={6} className="predicted-commissions-row-cell right-align">
                                {formatLocaleBruttoPoints(pcc.bruttoPointAmount)}
                              </Col>
                            </>
                          )}
                        </Row>
                        {pcc.individualBruttoPoints && <Divider className="predicted-commissions-divider" />}
                      </React.Fragment>
                    ))}

                    <Row>
                      <Col span={12}>
                        <b>{t("contract.attrs.predictedCommissions.commissionBaseAmount")}:&nbsp;</b>
                        {formatLocaleCurrency(contract.predictedCommission.commissionBaseAmount)}
                      </Col>
                      {isNumberValue(contract.predictedCommission.commissionAmount) && (
                        <Col span={6} className="right-align">
                          <b>{t("contract.attrs.predictedCommissions.commissionAmount")}:&nbsp;</b>
                          {formatLocaleCurrency(contract.predictedCommission.commissionAmount)}
                        </Col>
                      )}
                    </Row>

                    {!!contract.predictedCommission.errors.length && (
                      <Row>
                        <Col span={24} className="predicted-commissions-data-cell margin-top-tiny">
                          <Alert
                            type="error"
                            showIcon
                            message={
                              <Ellipsis>
                                {contract.predictedCommission.errors.flatMap(e => e.description).join(" ")}
                              </Ellipsis>
                            }
                            className="predicted-commission-errors-alert"
                          />
                        </Col>
                      </Row>
                    )}
                  </Collapse.Panel>
                </Collapse>
              </Col>
            </Row>
          </>
        )}
    </Card>
  );
};

export default ContractAgentsSection;
