import { Alert, Drawer, Form } from "antd";
import t from "../../../../app/i18n";
import { DrawerSizes } from "../../../../common/constants";
import { FieldConstraintViolation } from "../../../../common/types";
import { setErrorsToForm } from "../../../../common/utils/formUtils";
import { isDefined, parseBirthDateFromPin } from "../../../../common/utils/utils";
import { ClientFormType, ClientType } from "../../enums";
import { Client, CreateUpdateClient, LegalClient, NaturalClient, SelfEmployedClient } from "../../types";
import {
  createClientAggregatedName,
  createClientAggregatedNaturalName,
  processClientFormValues,
  resolveClientIdentifier
} from "../../utils";
import ClientFormBody from "../forms/ClientFormBody";
import ClientTypeTag from "../tags/ClientTypeTag";

interface Props<Type = ClientFormType> {
  open: boolean;
  client?: Client;
  initialClientType?: ClientType;
  initialIdentifier?: string;
  requireIdCardNumber?: boolean;
  formType?: Type;
  disabled?: boolean;
  violationErrors?: Map<Type, FieldConstraintViolation[]>;
  placement?: "calc" | "contract" | "mediation";
  onFormSubmit: (client: NaturalClient | SelfEmployedClient | LegalClient, clientFormType: Type) => void;
  onFormValuesChange?: (changedValues: any, values: any) => void;
}

const ClientDrawerForm = <Type,>(props: Props<Type>) => {
  const [form] = Form.useForm<CreateUpdateClient>();

  const handleFormSubmit = (clientData: CreateUpdateClient): void => {
    if (isDefined<number>(props.formType)) {
      const client = props.client
        ? (Object.assign({}, props.client, clientData) as NaturalClient | SelfEmployedClient | LegalClient)
        : ({ ...clientData } as NaturalClient | SelfEmployedClient | LegalClient);

      client.identifier = resolveClientIdentifier(client);
      client.aggregatedName = createClientAggregatedName(client);
      if (client.type === ClientType.SELF_EMPLOYED) {
        (client as SelfEmployedClient).aggregatedNaturalName = createClientAggregatedNaturalName(client);
      }
      if (client.type === ClientType.LEGAL && props.client) {
        (client as LegalClient).representatives = (props.client as LegalClient).representatives || [];
      }

      props.onFormSubmit(client, props.formType);
    }
  };

  const handleDrawerClose = (): void => {
    handleFormSubmit(processClientFormValues(form.getFieldsValue()));
  };

  const handleDrawerOpenChange = (open: boolean): void => {
    if (open && props.client && props.formType) {
      const violations = props.violationErrors?.get(props.formType);
      if (violations?.length) {
        setErrorsToForm(form, "client.attrs", violations);
      }
    }
  };

  const initialPinValue =
    !props.client &&
    (props.initialClientType === ClientType.NATURAL || (props.initialIdentifier && props.initialIdentifier?.length > 8))
      ? props.initialIdentifier
      : undefined;

  const initialCrnValue =
    !props.client &&
    (props.initialClientType === ClientType.SELF_EMPLOYED ||
      props.initialClientType === ClientType.LEGAL ||
      props.initialIdentifier?.length === 8)
      ? props.initialIdentifier
      : undefined;

  return (
    <Drawer
      title={
        <>
          <span>{props.client ? t("client.titles.update") : t("client.titles.create")}</span>
          <span>
            {props.client && (
              <>
                : {props.client.aggregatedName} <ClientTypeTag type={props.client.type} />{" "}
              </>
            )}
          </span>
          <div className="margin-top-tiny">
            <Alert
              showIcon
              message={
                props.placement === "contract"
                  ? t("client.helpers.clientDataUpdateInfoContract")
                  : props.placement === "mediation"
                    ? t("client.helpers.clientDataUpdateInfoMediation")
                    : t("client.helpers.clientDataUpdateInfoCalc")
              }
            />
          </div>
        </>
      }
      width={DrawerSizes.LARGE}
      open={props.open}
      closable
      destroyOnClose
      afterOpenChange={handleDrawerOpenChange}
      onClose={handleDrawerClose}
    >
      <Form
        form={form}
        layout="vertical"
        name="clientDrawerForm"
        disabled={props.disabled}
        initialValues={{
          personalIdentificationNumber: initialPinValue,
          birthDate: initialPinValue ? parseBirthDateFromPin(initialPinValue) : undefined,
          companyRegistrationNumber: initialCrnValue
        }}
        onValuesChange={props.onFormValuesChange}
      >
        <ClientFormBody
          form={form}
          client={props.client}
          initialClientType={props.initialClientType}
          excludedClientTypes={
            props.initialClientType
              ? undefined
              : props.initialIdentifier?.length === 8
                ? [ClientType.NATURAL]
                : [ClientType.SELF_EMPLOYED, ClientType.LEGAL]
          }
          disablePinInput={!!initialPinValue || props.client?.type === ClientType.NATURAL}
          disableCrnInput={
            !!initialCrnValue ||
            props.client?.type === ClientType.SELF_EMPLOYED ||
            props.client?.type === ClientType.LEGAL
          }
          requireIdCardNumber={props.requireIdCardNumber}
          viewType="drawer"
          onFormSubmit={handleFormSubmit}
        />
      </Form>
    </Drawer>
  );
};

export default ClientDrawerForm;
